import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { IconNames } from '@blueprintjs/icons';
import {
  UL,
  Card,
  H5,
  Button,
  Popover,
  Menu,
  Position,
  Switch,
  Breadcrumbs,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { useMission } from 'services/Missions';
import { useApiOpsGetTerminalSessions } from 'services/api/ops/terminalSessions';
import DefaultLayout from 'pages/ops/shared/components/DefaultLayout';
import NewTerminalSession from 'pages/ops/RTI/SessionManager/components/NewTerminalSession';
import { TerminalSessionItem } from 'pages/ops/RTI/SessionManager/components/TerminalSessionItem';
import { ops } from 'constants/mixpanelAnalytics';
import useOnLoadMetricWithUrlId from 'utils/hooks/analytics/useOnLoadMetricWithUrlId';
import mainS from '../index.module.scss';
import s from './index.module.scss';
import useGatewayStatus from 'pages/ops/RTI/SessionManager/hooks/useGatewayStatus';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import ActiveSessions from 'components/ActiveSessions';

const SessionManager = () => {
  const { currentMissionId } = useMission();

  const history = useHistory();
  const { sessions, fetchTerminalSessions, setMissionSessions } =
    useApiOpsGetTerminalSessions(currentMissionId);
  const { gatewayStatus, isFetching } = useGatewayStatus(currentMissionId);
  const [filterDeleted, setFilterDeleted] = useState(true);

  useOnLoadMetricWithUrlId('mission', {
    action: 'Visit',
    item: 'RTI',
    module: 'OPS',
    type: ops.RTI.VISIT,
  });

  const handleSessionRename = (id: number, name: string) => {
    const index = sessions.findIndex((session) => session.id === id);
    sessions[index].name = name;
    setMissionSessions(sessions);
  };

  const handleConnectToSession = (sessionId: number) => {
    if (!currentMissionId) return;
    history.push(`/ops/mission/${currentMissionId}/rti/session/${sessionId}`);
  };

  const gatewayStatusMessage =
    gatewayStatus?.message ?? 'No status is available';

  const { hasPermission: isAllowedToReadSession } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'ops:rti:session:read',
      id: currentMissionId,
    },
  });

  const { hasPermission: isAllowedToWriteSession } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'ops:rti:session:write',
      id: currentMissionId,
    },
  });

  return (
    <DefaultLayout
      centerContent
      showMissionSelector
      light
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'RTI' }]} />
        </div>
      }
      rightHeader={<ActiveSessions mission={currentMissionId} />}
    >
      <div className={s.container}>
        <H5>Create new session</H5>
        <Card className={s.newSessionContainer}>
          <NewTerminalSession
            handleConnectToSession={handleConnectToSession}
            writeAllSessionsPermission={Boolean(isAllowedToReadSession)}
            onSessionCreated={fetchTerminalSessions}
          />
        </Card>

        <H5>Gateway connection status</H5>
        <p>{isFetching ? 'loading...' : gatewayStatusMessage}</p>

        <H5>
          <span>Existing sessions</span>
          <Tooltip content="Reload list">
            <Button
              minimal
              icon={IconNames.REFRESH}
              onClick={fetchTerminalSessions}
            />
          </Tooltip>
          <Popover position={Position.BOTTOM}>
            <Tooltip content="Filters">
              <Button
                minimal
                icon={IconNames.SETTINGS}
                rightIcon={IconNames.CARET_DOWN}
              />
            </Tooltip>
            <Menu>
              <Switch
                checked={filterDeleted}
                label="Filter deleted"
                onChange={(e) => {
                  setFilterDeleted(e.currentTarget.checked);
                }}
              />
            </Menu>
          </Popover>
        </H5>
        <Card className={s.newSessionContainer}>
          <UL className={s.list}>
            {sessions
              .filter((item) => item.open || !filterDeleted)
              .map((session) => (
                <TerminalSessionItem
                  key={session.id}
                  session={session}
                  handleSessionRename={(name: string) =>
                    handleSessionRename(session.id, name)
                  }
                  handleConnectToSession={handleConnectToSession}
                  handleReloadSessions={fetchTerminalSessions}
                  readAllSessionsPermission={Boolean(isAllowedToReadSession)}
                  writeAllSessionsPermission={Boolean(isAllowedToWriteSession)}
                />
              ))}
          </UL>
        </Card>
      </div>
    </DefaultLayout>
  );
};

export default SessionManager;
