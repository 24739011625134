type AnyObject = Record<
  string,
  string | number | boolean | Object | null | undefined
>;

/**
 * mergeObjects is a function that merges multiple objects into a single object.
 * It does a deep merge, meaning that it will merge nested objects as well.
 *
 * This is used to obtain every possible column from a list of objects.
 * @param objects
 * @returns
 */
export function mergeObjects(objects: AnyObject[]): AnyObject {
  return objects.reduce((acc, obj) => mergeDeep(acc, obj), {});
}

function mergeDeep(target: AnyObject, source: AnyObject): AnyObject {
  for (const key in source) {
    if (
      source[key] !== null &&
      typeof source[key] === "object" &&
      !Array.isArray(source[key])
    ) {
      // If the value is an object, recursively merge it
      target[key] = mergeDeep(
        (target[key] as AnyObject) || {},
        source[key] as AnyObject
      );
    } else if (source[key] !== null && target[key] == null) {
      // If the source value is not null and target value is null or undefined, use the source value
      target[key] = source[key];
    } else if (target[key] == null) {
      // If the target value is still null or undefined, use the source value
      target[key] = source[key];
    }
  }

  return target;
}

/**
 * capitalizeTitle is a helper function that capitalizes the first letter of the string.
 * @param str string to capitalize
 * @returns capitalized string
 */
export const capitalizeTitle = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
