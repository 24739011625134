import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from '_molecules/DatePicker/DatePicker';
import OpenedAppCard from './Common/OpenedAppCard';
import UnopenedAppCard from './Common/UnopenedAppCard';
import { parseDate } from '@internationalized/date';
import IconButton from '_molecules/IconButton/IconButton';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import { btoaSafe } from 'utils/common/btoaSafe';
import { postTicket } from '_api/tickets/service';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const QuasarMaritimeMonitoringApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Maritime Monitoring',
  description:
    'Sentinel-1 provides global, regular and repeated coverage of coastal and open sea waters. With its Synthetic Aperture Radar (SAR), Sentinel-1 has the advantage of operating under cloud cover or a lack of illumination and can acquire data over a site during day or night time under all weather conditions. One of the many applications of Sentinel-1 SAR data is for Maritime Monitoring, making it suitable for vessel detection. Quasar Science Resources provides a fully automatic ship detection algorithm for Sentinel-1 SAR images, providing a long-term, wide-area monitoring of vessel traffic along with vessel location and size estimators and classification. Automatic vessel detection, without human intervention, requires reliable results in order to remove false alarms which are not due to vessels. As an example, for boats down to a size of at least 30 meters in length, the success rate is more than 80%. Products include, Sentinel-1 SAR processed image and vessel detection shapefile with location and size estimator.',
  shortDescription:
    'Applications include: Vessel and vessel traffic spatial density and distribution, Derivation of most likely vessel routes, Identification of static and mobile maritime objects, Hotspot identification and their temporal and spatial evolution, Cross-correlation with AIS or other external sources of data, Cross-correlation with location of Marine Ecosystems',
  provider: {
    name: 'Quasar Science Resources, S.L.',
    description: '',
    id: 12,
    url: 'https://quasarsr.com/',
    icon_url: DATACOSMOS_IMG + 'quasar_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'quasar_maritime_screenshot.jpg',
  frequencyOfData: 'Between 1 and 7 images per week',
  price: 'Contact us',
  fee: 'Cost Details: Number of images to be analysed and whether any kind of technical report is needed or not to accompany the images.',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDate',
      example: '',
    },
    {
      field: 'endDate',
      example: '',
    },
    {
      field: 'analysisType',
      example: '',
    },
  ],
  values: {
    AOI: { value: undefined, isError: false, message: '' },
    startDate: { value: undefined, isError: false, message: '' },
    endDate: { value: undefined, isError: false, message: '' },
    analysisType: { value: undefined, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <QuasarMaritimeMonitoring app={app} />,
  leadTime:
    'This will depend on the product and satellite pass. Urgent requests less than 48 hours after satellite overpass, but should be reflected on price. Otherwise, a few days after satellite overpass. Some products might take longer due to the fact that a data over a period of time might need to be accumulated.',
  tags: [AppTags.maritime],
};

const ANALYSIS_TYPES = [
  'Vessel and vessel traffic spatial density and distribution',
  'Derivation of most likely vessel routes',
  'Identification of static and mobile maritime objects',
  'Hotspot identification and their temporal and spatial evolution',
  'Cross-correlation with AIS or other external sources of data',
  'Cross-correlation with location of Marine Ecosystems',
];

const QuasarMaritimeMonitoring = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setQuasarAOI,
    applicationAOIs: quasarAOI,
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();
  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [analysisType, setAnalysisType] = useState<string>();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  useEffect(() => {
    setValue('startDate', startDate);
    setValue('AOI', quasarAOI);
    setValue('analysisType', analysisType);
    setValue('endDate', endDate);
  }, [startDate, quasarAOI, analysisType, endDate, setValue]);

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setQuasarAOI}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setQuasarAOI}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setQuasarAOI([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div id="endDate">
          <DatePicker
            fill
            label="End date: "
            onChange={(d) => setEndDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div>
          <Select2
            onSelectionChange={(item) => setAnalysisType(item.toString())}
            fill
            label={<label htmlFor="analysis">Analysis type: </label>}
            selectedItemClassName="border-2 border-item"
          >
            {ANALYSIS_TYPES.map((item) => (
              <Item key={item}>{item}</Item>
            ))}
          </Select2>
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();

    void postTicket({
      body: {
        title: `DataCosmos Subscription to ${app.name}`,
        description: mailBody,
        team: 'datacosmos',
      },
    });
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(a) => {
        setSelectedInstalledApp(a);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
