import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import Tooltip from '_atoms/Tooltip/Tooltip';
import type { TaskingRequest } from '_api/tasking/service';
import { postSwathSearch, patchTaskingRequest } from '_api/tasking/service';
import {
  getRegionOfInterestCoverageArea,
  getRegionOfInterestCoveragePercent,
} from 'datacosmos/utils/geojson';
import { SwathControl } from '_organisms/SwathControl/SwathControl';
import { useSwathControlData } from 'datacosmos/utils/hooks/useSwathControlData';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type {
  AoiLayersDisplayed,
  RemoveSwathEdits,
  SwathLayersDisplayed,
  ToggleDisplayActivitySwathOnMap,
  ToggleDisplayAoIOnMap,
  UpdateSwathLayer,
} from './useTaskingRequestList';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import { useRouteMatch } from 'react-router';
import {
  Button,
  Icon,
  Menu,
  MenuTrigger,
  Popover,
  MenuItem,
  Dialog,
} from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import AdvancedParametersInfo from './AdvancedParametersInfo';
import type { Activity } from '_api/activities/types';

type IProps = {
  activity: Activity;
  request: TaskingRequest;
  toggleDisplayAoIOnMap: ToggleDisplayAoIOnMap;
  toggleDisplayActivitySwathOnMap: ToggleDisplayActivitySwathOnMap;
  shownAoiLayer?: AoiLayersDisplayed[0];
  shownActivitySwathsLayer?: SwathLayersDisplayed[0];
  updateSwathLayer: UpdateSwathLayer;
  removeSwathEdits: RemoveSwathEdits;
  refetchRequests: () => Promise<void>;
};

const getDuration = (activity: Activity) => {
  const diff = moment.utc(moment(activity.end_date).diff(activity.start_date));

  if (diff.minutes() > 0) {
    return moment
      .utc(moment(activity.end_date).diff(activity.start_date))
      .format('mm[m] ss[s]');
  }

  return moment
    .utc(moment(activity.end_date).diff(activity.start_date))
    .format('ss[s]');
};

const ActivityCard = ({
  activity,
  request,
  toggleDisplayAoIOnMap,
  toggleDisplayActivitySwathOnMap,
  shownAoiLayer,
  shownActivitySwathsLayer,
  updateSwathLayer,
  removeSwathEdits,
  refetchRequests,
}: IProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [
    isOpenCancelActivityConfirmation,
    setIsOpenCancelActivityConfirmation,
  ] = useState<boolean>(false);

  const { hasPermission: isAllowedToEditTaskingRequest } = useCheckPermissions({
    permissions: [
      {
        id: activity.mission_id,
        type: 'mission',
        actionScope:
          'data:tasking:request:update:activities:parameters:platform:roll_angle',
      },
      {
        id: activity.mission_id,
        type: 'mission',
        actionScope: 'data:tasking:request:update:activities:start_date',
      },
      {
        id: activity.mission_id,
        type: 'mission',
        actionScope: 'data:tasking:request:update:activities:end_date',
      },
    ] as ICheckPermissions[],
  });

  const {
    debouncedSetSwathData,
    swathControlData,
    resetSwathDataToDefault,
    setSwathControlData,
  } = useSwathControlData({
    area:
      request && activity.parameters.physical?.latest
        ? getRegionOfInterestCoverageArea(
            [activity.parameters.physical.latest.geojson],
            [request.region]
          )
        : 0,
    coverage:
      request && activity.parameters.physical?.latest
        ? getRegionOfInterestCoveragePercent(
            [activity.parameters.physical.latest.geojson],
            [request.region]
          )
        : 0,
    duration: {
      end: moment.utc(activity.end_date).toDate(),
      start: moment.utc(activity.start_date).toDate(),
    },
    rotation: activity.parameters?.platform?.roll_angle ?? 0,
    sza: activity.parameters.physical?.latest?.midpoint.sza_deg,
    oza: activity.parameters.physical?.latest?.midpoint.oza_deg,
    parameters: activity.parameters,
  });

  const isSwathShown = typeof shownActivitySwathsLayer !== 'undefined';
  const isAoIShown = typeof shownAoiLayer !== 'undefined';

  const { translate } = useLocalisation();

  const isInPast = useMemo<boolean>(() => {
    return moment(moment.now()).diff(activity.start_date) >= 0;
  }, [activity.start_date]);

  const urlMatch = useRouteMatch<{ projectId: string }>({
    path: '/data/project/:projectId',
    exact: false,
  });

  const projectId = urlMatch?.params.projectId;

  const disableEditButton =
    activity.status !== 'PENDING' ||
    isInPast ||
    !isAllowedToEditTaskingRequest.reduce((prev, current) => prev && current) ||
    request?.type === 'AUTOMATED';

  const disableCancelButton =
    !activity.is_cancellable ||
    (activity.status !== 'PENDING' && activity.status !== 'APPROVED');

  const [isOpen, setOpen] = useState(false);
  const [detailsPopoverContent, setDetailsPopoverContent] = useState<
    Activity | undefined
  >(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const toggleDisplayActivityDetails = (clickedActivity: Activity) => {
    setDetailsPopoverContent(clickedActivity);
    setOpen(true);
  };

  interface IItemProps {
    text: string;
    icon?: IconName;
  }

  const TaskingOptionsItem = ({ text, icon }: IItemProps) => {
    return (
      <div className="flex gap-2 items-center">
        {icon && <Icon icon={icon} />}
        {text}
      </div>
    );
  };

  const taskingOptionsContent = (
    <Menu
      disabledKeys={[
        'scene',
        disableEditButton ? 'edit' : undefined,
        disableCancelButton ? 'cancel' : undefined,
      ].filter((a): a is string => typeof a !== 'undefined')}
      onAction={(action) => {
        switch (action) {
          case 'edit':
            if (!isAoIShown) {
              toggleDisplayAoIOnMap(request);
            }

            if (!isSwathShown) {
              toggleDisplayActivitySwathOnMap(activity);
            }
            setIsEditing(true);
            return;
          case 'details':
            toggleDisplayActivityDetails(activity);
            return;
          case 'scene':
            //TODO: should display stac item from activity
            return;
          case 'swath':
            toggleDisplayActivitySwathOnMap(activity);
            return;
          case 'cancel':
            setIsOpenCancelActivityConfirmation(true);
            return;
        }
      }}
    >
      <MenuItem id={'details'}>
        <TaskingOptionsItem text={'Open details'} />
      </MenuItem>
      <MenuItem id={'edit'}>
        <TaskingOptionsItem text={'Edit'} icon={'Pencil'} />
      </MenuItem>
      <MenuItem id={'scene'}>
        <TaskingOptionsItem text={'Go to scene'} icon={'Image'} />
      </MenuItem>
      <MenuItem id={'swath'} data-testid="request-add-single-swath">
        <TaskingOptionsItem
          text={isSwathShown ? 'Hide swath' : 'Display swath'}
          icon={'SwathMultiple'}
        />
      </MenuItem>
      <MenuItem id={'cancel'}>
        <TaskingOptionsItem text={'Cancel'} />
      </MenuItem>
    </Menu>
  );

  return (
    <div className="bg-item dark:bg-item-dark flex text-xs items-center p-1 gap-1">
      <div className="flex flex-1 flex-col gap-2" ref={triggerRef}>
        <div className="flex items-center gap-20 border-b border-b-item-contrast-inactive dark:border-b-item-dark-contrast-inactive pb-2">
          <div className="flex flex-col gap-1">
            <TaskingOptionsItem
              text={moment(activity.start_date).format(
                'YYYY MMM DD - HH:mm:ss'
              )}
              icon={'Calendar'}
            />
            <Tooltip
              content={`${translate(
                'datacosmos.tooltips.tasking.existing.activities.satellite'
              )} / ${translate(
                'datacosmos.tooltips.tasking.existing.activities.imager'
              )}`}
              delay={0}
            >
              <TaskingOptionsItem
                text={`${activity.mission_name} / ${activity.parameters?.imager?.name}`}
                icon={'Satellite'}
              />
            </Tooltip>
          </div>

          <div className="flex flex-col gap-1">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.duration'
              )}
              delay={0}
            >
              <TaskingOptionsItem text={getDuration(activity)} icon={'Clock'} />
            </Tooltip>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.processingLevel'
              )}
              delay={0}
            >
              <TaskingOptionsItem
                text={
                  swathControlData?.parameters?.ground_processing
                    ?.processing_level ?? '-'
                }
                icon={'Layers'}
              />
            </Tooltip>
          </div>
        </div>

        <div className="flex items-center justify-between text-[10px]">
          <div className="flex items-center gap-2">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.aoiCoverage'
              )}
              delay={0}
            >
              <span className="flex items-center gap-2">
                <Icon icon="Coverage" />
                {request && activity.parameters.physical?.latest
                  ? getRegionOfInterestCoveragePercent(
                      [activity.parameters.physical?.latest.geojson],
                      [request.region]
                    )
                  : '- %'}
                %
              </span>
            </Tooltip>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.oza'
              )}
              delay={0}
            >
              <span className="flex items-center gap-1">
                <Icon icon="Angle" />
                {swathControlData.oza?.toFixed(2) ??
                  activity.parameters.physical?.latest?.midpoint?.oza_deg?.toFixed(
                    2
                  )}
                °
              </span>
            </Tooltip>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.sza'
              )}
              delay={0}
            >
              <span className="flex items-center gap-1">
                <Icon icon="SunAngle" />
                {swathControlData.sza?.toFixed(2) ??
                  activity.parameters.physical?.latest?.midpoint?.sza_deg?.toFixed(
                    2
                  )}
                °
              </span>
            </Tooltip>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.rollAngle'
              )}
              delay={0}
            >
              <span className="flex items-center gap-1">
                <Icon icon="SatelliteRollAngle" />
                {swathControlData?.rotation?.toFixed(2)}°
              </span>
            </Tooltip>
          </div>
          <Tooltip
            content={translate(
              `datacosmos.tasking.existing.activityStatus.${activity.status}.description`
            )}
          >
            <div className="flex items-center gap-1">
              {translate(
                `datacosmos.tasking.existing.activityStatus.${activity.status}.title`
              )}
              <Icon icon="Info" size={12} />
            </div>
          </Tooltip>
        </div>

        {isEditing && (
          <div className="mt-4" onClick={(e) => e.stopPropagation()}>
            <SwathControl
              opportunity={request}
              swath={activity}
              setSwathControlData={(data) => {
                debouncedSetSwathData(data, async () => {
                  if (!request) {
                    return;
                  }
                  if (!projectId) {
                    return;
                  }

                  const updated = await postSwathSearch({
                    body: {
                      area_of_interest: {
                        name: request.region_name,
                        geojson: {
                          geometry: request.region.geometry,
                          properties: {},
                          type: request.region.type,
                        },
                      },
                      instrument: {
                        mission_id: activity.mission_id,
                        sensor_id: activity.parameters?.imager?.name ?? '',
                      },
                      roll_angle: data.rotation,
                      start: data.duration.start.toISOString(),
                      stop: data.duration.end.toISOString(),
                      project_id: projectId,
                    },
                  });

                  if (!updated.data) return;

                  updateSwathLayer(activity, updated.data);

                  // Set oza, sza additionally
                  setSwathControlData({
                    ...data,
                    oza: updated.data?.midpoint.oza_deg,
                    sza: updated.data?.midpoint.sza_deg,
                  });
                });
              }}
              swathControlData={swathControlData}
            />

            <div className="flex w-full justify-between gap-2">
              <Button
                className="w-full !bg-item-contrast-surface"
                onPress={() => {
                  setIsEditing(false);
                  // Disabled the check here because I have no idea why this would be a problem
                  // considering the function has an explicitly defined void return type

                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  resetSwathDataToDefault();
                }}
              >
                {translate('datacosmos.buttons.cancel')}
              </Button>

              <Button
                className="w-full  !bg-item-contrast-surface"
                onPress={async () => {
                  const updatedParameters = { ...swathControlData?.parameters };
                  delete updatedParameters?.physical;
                  const { success } = await patchTaskingRequest({
                    body: {
                      activities: [
                        {
                          id: activity.id,
                          end_date: swathControlData.duration.end.toISOString(),
                          start_date:
                            swathControlData.duration.start.toISOString(),
                          priority: swathControlData?.priority?.priority_level,
                          parameters: updatedParameters,
                        },
                      ],
                    },
                    params: {
                      requestId: request.id,
                    },
                  });

                  setIsEditing(false);
                  if (success) {
                    await refetchRequests();
                  }
                  removeSwathEdits(activity);
                }}
              >
                {translate('datacosmos.buttons.update')}
              </Button>
            </div>
          </div>
        )}
      </div>
      {isEditing ? null : (
        <MenuTrigger>
          <Button
            icon="ThreePointsVertical"
            aria-label="Menu"
            data-testid="tasking-options-menu"
            isMinimal
            isTransparent
            style={{
              height: triggerRef?.current?.clientHeight,
              maxHeight: 'unset',
              padding: 0,
            }}
            ref={buttonRef}
          />
          <Popover placement="right top">{taskingOptionsContent}</Popover>
        </MenuTrigger>
      )}

      <AdvancedParametersInfo
        isOpen={isOpen}
        onOpenChange={() => {
          setOpen(!isOpen);
        }}
        triggerRef={buttonRef}
        popoverContent={
          detailsPopoverContent?.parameters as unknown as {
            [key: string]: unknown;
          }
        }
      />

      <ConfirmCancelActivity
        isOpen={isOpenCancelActivityConfirmation}
        setIsOpen={setIsOpenCancelActivityConfirmation}
        request={request}
        activity={activity}
        refetchData={refetchRequests}
      />
    </div>
  );
};

type CancelActivityProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  request: TaskingRequest;
  activity: Activity;
  refetchData?: () => Promise<void>;
};

const ConfirmCancelActivity = ({
  isOpen,
  setIsOpen,
  request,
  activity,
  refetchData,
}: CancelActivityProps) => {
  const { translate } = useLocalisation();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);

  const cancelActivity = async (requestId: string, activityId: string) => {
    if (!requestId) {
      return;
    }
    setIsUpdatingStatus(true);
    const data = await patchTaskingRequest({
      params: { requestId: requestId },
      body: { activities: [{ id: activityId, status: 'CANCELLED' }] },
    });
    setIsUpdatingStatus(false);
    return data;
  };
  return (
    <Dialog
      isOpen={isOpen}
      hideCancelButton
      onClose={() => setIsOpen(false)}
      title={translate('datacosmos.cancelRequestDialog.title')}
      buttons={[
        {
          text: translate('datacosmos.buttons.confirm'),
          shown: true,
          showLoadingIndicator: isUpdatingStatus,
          onPress: async () => {
            const activityData = await cancelActivity(request.id, activity.id);
            setIsOpen(false);
            if (activityData?.success && refetchData) {
              await refetchData();
            }
          },
        },
        {
          text: translate('datacosmos.buttons.cancel'),
          shown: true,
          onPress: () => setIsOpen(false),
        },
      ]}
    >
      <div className="flex flex-col gap-2">
        <h4>
          {translate('datacosmos.cancelActivityDialog.areYouSure', {
            date: moment(activity.start_date).format('YYYY MMM DD - HH:mm:ss'),
            duration: getDuration(activity),
          })}
        </h4>

        <div className="flex items-center gap-2 bg-accent-200 dark:bg-accent-dark my-2 p-1 text-xs dark:text-item-contrast">
          <Icon icon="Info" />
          <span>
            {translate('datacosmos.cancelActivityDialog.note', {
              price: activity.price,
            })}
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default ActivityCard;
