import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import type { Layer } from 'datacosmos/entities/layer';
import {
  getJoinedOverlapingArea,
  getRegionOfInterestCoverageArea,
  getRegionOfInterestCoveragePercent,
  getTotalArea,
} from 'datacosmos/utils/geojson';
import { useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import {
  getActivityCategory,
  ACTIVITY_CATEGORY_LIST,
} from '_api/activities/helpers';
import type { Activity } from '_api/activities/types';
import type { ActivityCategory } from '_api/activities/helpers';
import IconButton from '_molecules/IconButton/IconButton';
import { TaskingPanel } from 'datacosmos/components/Tasking/helpers';
import s from 'datacosmos/components/TaskingLegend.module.scss';
import classNames from 'classnames';
import {
  getLayersWithImager,
  getLayersWithType,
} from 'datacosmos/entities/helpers';
import type { PartialSwathLayer } from 'datacosmos/entities/helpers';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import type { CommonOpportunity } from '_api/tasking/helpers';
import { Button } from '@blueprintjs/core';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { zIndexValues } from 'opencosmos-ui/constants';

const categorizeSwaths = (metadata: PartialSwathLayer[]) => {
  const categorized: { [key: string]: PartialSwathLayer[] } = {};
  metadata.forEach((data) => {
    const category = getActivityCategory(data.metadata.status);
    if (Array.isArray(categorized[category])) {
      categorized[category].push(data);
    } else {
      categorized[category] = [data];
    }
  });
  return categorized;
};

type TaskingLegendProps = {
  showOnRoute: string;
  rightMenuOpen: boolean;
  layers: Layer[];
};

export const TaskingLegend = ({
  showOnRoute,
  rightMenuOpen,
  layers,
}: TaskingLegendProps) => {
  const selectedRouteMatch = useRouteMatch(showOnRoute);
  const matchesRoute = selectedRouteMatch?.isExact;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { replaceLayer } = useMapLayers();

  const { translate } = useLocalisation();

  const [includedInCalc, setIncludedInCalc] = useState<ActivityCategory[]>(
    ACTIVITY_CATEGORY_LIST as unknown as ActivityCategory[]
  );

  const swathMetadata = useMemo(() => getLayersWithImager(layers), [layers]);

  const categorizedSwaths = useMemo(
    () => categorizeSwaths(swathMetadata),
    [swathMetadata]
  );

  const requestRoiLayers = useMemo(() => getLayersWithType(layers), [layers]);

  const toUseForCalcs = useMemo(() => {
    const forCalculations = includedInCalc.reduce<PartialSwathLayer[]>(
      (acc, status) => {
        if (Array.isArray(categorizedSwaths[status])) {
          acc.push(...categorizedSwaths[status]);
        }
        return acc;
      },
      []
    );
    return swathMetadata.filter((layer) =>
      forCalculations.some((afc) => layer.metadata.id === afc.metadata.id)
    ) as GeoJSONLayer<Activity>[];
  }, [categorizedSwaths, includedInCalc, swathMetadata]);

  const uniqueAreaPercent = useMemo(() => {
    const overlapingArea = getJoinedOverlapingArea(toUseForCalcs) * 100;
    const uniqueArea = overlapingArea / getTotalArea(toUseForCalcs);
    if (Number.isNaN(uniqueArea)) return '0.00';
    return uniqueArea.toFixed(2);
  }, [toUseForCalcs]);

  const ogSwaths = useMemo(
    () =>
      layers
        .filter((l) => l instanceof SwathLayer)
        .filter((l) => l.getName().includes('-target')) as
        | SwathLayer<CommonOpportunity>[]
        | undefined,
    [layers]
  );

  if (!matchesRoute) {
    return <div></div>;
  }

  if (location.pathname.includes('ops')) {
    return (
      <div
        style={{ zIndex: 1000 }}
        className={classNames(
          'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
          'p-2 flex flex-col h-fit w-fit min-w-[220px] absolute right-[5rem] top-[60px]'
        )}
      >
        <div className={s.legendKeyWrapperExistingRequests}>
          <div className={[s.colorBox, s.swath].join(' ')} />
          <span>{translate('datacosmos.tasking.legend.currentSwath')}</span>
        </div>

        <div className={s.legendKeyWrapperExistingRequests}>
          <div className={[s.colorBox, s.targetSwath].join(' ')} />
          <span>{translate('datacosmos.tasking.legend.target')}</span>
          <Button
            icon={
              ogSwaths?.every((og) => og.options.visible) && ogSwaths.length > 0
                ? 'eye-open'
                : 'eye-off'
            }
            minimal
            onClick={() => {
              if (ogSwaths?.every((og) => og.options.visible)) {
                ogSwaths.map((og) =>
                  replaceLayer(og.cloneWithOptions({ visible: false }))
                );
              } else {
                ogSwaths?.map((og) =>
                  replaceLayer(og.cloneWithOptions({ visible: true }))
                );
              }
            }}
          />
        </div>

        <div className={s.legendKeyWrapperExistingRequests}>
          <div className={[s.colorBox, s.areaOfInterest].join(' ')} />
          <span>{translate('datacosmos.tasking.legend.aoi')}</span>
        </div>
      </div>
    );
  }

  if (queryParams.get('panel') === TaskingPanel.New) {
    return (
      <div
        className={classNames(
          s.legendContainer,
          'dark:bg-surface-dark p-2 dark:text-item-dark-contrast'
        )}
        style={{ right: rightMenuOpen ? '450px' : '5rem' }}
      >
        <div className={s.legendKeyWrapper}>
          <div className={[s.colorBox, s.fieldOfRegard].join(' ')} />
          <span>{translate('datacosmos.tasking.legend.fieldOfRegard')}</span>
        </div>

        <div className={s.legendKeyWrapper}>
          <div className={[s.colorBox, s.swath].join(' ')} />
          <span>{translate('datacosmos.tasking.legend.currentSwath')}</span>
        </div>

        <div className={s.legendKeyWrapper}>
          <div className={[s.colorBox, s.confirmedSwath].join(' ')} />
          <span>{translate('datacosmos.tasking.legend.confirmedSwath')}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
        'p-2 flex flex-col h-fit w-fit min-w-[220px] absolute right-[5rem] top-[60px]',
        `z-[${zIndexValues.default}]`
      )}
      style={{ right: rightMenuOpen ? '550px' : '5rem' }}
    >
      <div className={s.legendKeyWrapperExistingRequests}>
        <div className={[s.colorBox, s.fieldOfRegard].join(' ')} />
        <span>{translate('datacosmos.tasking.legend.failed')}</span>
        <IconButton
          icon={
            includedInCalc.find((iic) => iic === 'FAILED')
              ? 'Calculator'
              : 'CalculatorStrikethrough'
          }
          size={24}
          onPress={() => {
            if (includedInCalc.find((iic) => iic === 'FAILED')) {
              setIncludedInCalc((prev) => prev.filter((p) => p !== 'FAILED'));
            } else {
              setIncludedInCalc((prev) => [...prev, 'FAILED']);
            }
          }}
        />
      </div>

      <div className={s.legendKeyWrapperExistingRequests}>
        <div className={[s.colorBox, s.plannedSwath].join(' ')} />
        <span>{translate('datacosmos.tasking.legend.planned')}</span>
        <IconButton
          icon={
            includedInCalc.find((iic) => iic === 'PLANNED')
              ? 'Calculator'
              : 'CalculatorStrikethrough'
          }
          size={24}
          onPress={() => {
            if (includedInCalc.find((iic) => iic === 'PLANNED')) {
              setIncludedInCalc((prev) => prev.filter((p) => p !== 'PLANNED'));
            } else {
              setIncludedInCalc((prev) => [...prev, 'PLANNED']);
            }
          }}
        />
      </div>

      <div className={s.legendKeyWrapperExistingRequests}>
        <div className={[s.colorBox, s.confirmedSwath].join(' ')} />
        <span>{translate('datacosmos.tasking.legend.succeeded')}</span>
        <IconButton
          icon={
            includedInCalc.find((iic) => iic === 'COMPLETED')
              ? 'Calculator'
              : 'CalculatorStrikethrough'
          }
          size={24}
          onPress={() => {
            if (includedInCalc.find((iic) => iic === 'COMPLETED')) {
              setIncludedInCalc((prev) =>
                prev.filter((p) => p !== 'COMPLETED')
              );
            } else {
              setIncludedInCalc((prev) => [...prev, 'COMPLETED']);
            }
          }}
        />
      </div>

      <div className={s.legendKeyWrapperExistingRequests}>
        <div className={[s.colorBox, s.targetath].join(' ')} />
        <span>{translate('datacosmos.tasking.legend.target')}</span>
        <Button
          icon={
            ogSwaths?.every((og) => og.options.visible) && ogSwaths.length > 0
              ? 'eye-open'
              : 'eye-off'
          }
          minimal
          onClick={() => {
            if (ogSwaths?.every((og) => og.options.visible)) {
              ogSwaths.map((og) =>
                replaceLayer(og.cloneWithOptions({ visible: false }))
              );
            } else {
              ogSwaths?.map((og) =>
                replaceLayer(og.cloneWithOptions({ visible: true }))
              );
            }
          }}
        />
      </div>

      <div className="mt-2 flex flex-col gap-1 text-sm">
        <div className="flex items-center justify-between">
          <span>{translate('datacosmos.tasking.legend.totalArea')}</span>
          {toUseForCalcs.length > 0 ? (
            <span>{getTotalArea(toUseForCalcs)} km²</span>
          ) : (
            '- km²'
          )}
        </div>

        <div className="flex items-center justify-between gap-2">
          <span>{translate('datacosmos.tasking.legend.uniqueArea')}</span>
          {toUseForCalcs.length > 0 ? (
            <span>
              {getJoinedOverlapingArea(toUseForCalcs)} km² ({uniqueAreaPercent}
              %)
            </span>
          ) : (
            '- km²(%)'
          )}
        </div>

        <div className="flex items-center justify-between">
          <span>{translate('datacosmos.tasking.legend.roiCoverage')}</span>
          {toUseForCalcs.length > 0 && requestRoiLayers.length > 0 ? (
            <span>
              {getRegionOfInterestCoverageArea(
                toUseForCalcs.map(
                  (calcSwath) => calcSwath.data
                ) as GeoJSON.Feature<GeoJSON.Polygon>[],
                requestRoiLayers.map(
                  (roi) => (roi as { data?: unknown } | undefined)?.data
                ) as GeoJSON.Feature<GeoJSON.Polygon>[]
              )}{' '}
              km² (
              {getRegionOfInterestCoveragePercent(
                toUseForCalcs.map(
                  (calcSwath) => calcSwath.data
                ) as GeoJSON.Feature<GeoJSON.Polygon>[],
                requestRoiLayers.map(
                  (roi) => (roi as { data?: unknown } | undefined)?.data
                ) as GeoJSON.Feature<GeoJSON.Polygon>[]
              )}
              %)
            </span>
          ) : (
            '- km²(%)'
          )}
        </div>
      </div>
    </div>
  );
};
