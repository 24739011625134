import React from 'react';
import moment from 'moment';
import type { IconName } from '@blueprintjs/core';
import {
  Icon,
  Classes,
  ContextMenu,
  Menu,
  MenuItem,
  Position,
  PopoverPosition,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';

import useFileDnd from 'pages/ops/RTI/Operate/hooks/fileExplorer/useFileDnd';
import { removeFirstSlash } from 'utils/common/stringUtils';
import { EntryType } from 'constants/fileExplorer/constants';

import s from './index.module.scss';
import classNames from 'classnames';

export interface ITreeNode {
  name?: string;
  icon?: IconName;
  type: EntryType;
  aliasName?: string;
  lastModified?: string;
  dragType?: string;
  acceptedDropType?: string;
  size?: number;
  path?: string;
}

interface IProps {
  content: ITreeNode;
  handleItemClick?: (name: string) => void;
  handleItemPreview?: (name: string) => void;
  handleItemDownloadClick?: (name: string) => void;
  handleDeleteItemClick?: (name: string, path: string) => void;
  handleRenameItemClick?: () => void;
  handleCopyFilePath?: (name: string) => void;
  handleDropItem?: (fromPath: string, toPath: string) => void;
  handlePropertiesDialogOpen?: (node: ITreeNode) => void;
  simplifiedExplorer?: boolean;
  getIsColumnHidden?: (columnName: string) => boolean;
}

const FolderNodeTree = (props: IProps) => {
  const {
    name,
    aliasName,
    icon,
    type,
    lastModified,
    size,
    dragType,
    path,
    acceptedDropType,
  } = props.content;

  const { isOverCurrent, isOver, drop, drag } = useFileDnd(
    acceptedDropType ?? '',
    path ?? '',
    dragType ?? '',
    props.handleDropItem
  );

  const showContextMenu = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    ContextMenu.show(
      <Menu>
        {type === EntryType.FILE && props.handleItemPreview && (
          <MenuItem
            onClick={() =>
              name && props.handleItemPreview && props.handleItemPreview(name)
            }
            text="Open"
          />
        )}
        {type === EntryType.FILE && props.handleItemClick && (
          <MenuItem
            onClick={() =>
              props.handleItemDownloadClick &&
              name &&
              props.handleItemDownloadClick(name)
            }
            text="Download"
            // @ts-expect-error
            name="tree-node-menu-download"
          />
        )}
        {
          /* todo hidden rename logic */ props.handleRenameItemClick && (
            <MenuItem text="Rename" />
          )
        }
        <MenuItem
          onClick={() =>
            props.handleCopyFilePath &&
            path &&
            props.handleCopyFilePath(removeFirstSlash(path))
          }
          text="Copy Path"
        />
        <MenuItem
          onClick={() => props.handlePropertiesDialogOpen?.(props.content)}
          text="Properties"
        />
        {props.handleDeleteItemClick && (
          <MenuItem
            className={Classes.INTENT_DANGER}
            onClick={() =>
              name &&
              path &&
              props.handleDeleteItemClick?.(
                type === EntryType.FILE ? name : name + '/',
                path
              )
            }
            text="Delete"
          />
        )}
      </Menu>,
      { left: e.clientX, top: e.clientY }
    );
  };

  const displayName = name ? (aliasName ?? name).split('/')[0] : '';

  const chooseNodeClassName =
    isOverCurrent && isOver
      ? [Classes.TREE_NODE, s.isDropable].join(' ')
      : Classes.TREE_NODE;

  return (
    <li
      ref={type !== EntryType.FILE ? drop : null}
      className={chooseNodeClassName}
    >
      <div
        ref={props.handleDropItem ? drag : null}
        className={classNames(Classes.TREE_NODE_CONTENT, s.folderNode)}
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          if (name && props.handleItemClick) {
            props.handleItemClick(name);
          }
        }}
        onClick={() => {
          if (name && props.handleItemClick) {
            props.handleItemClick(name);
          }
        }}
        onContextMenu={showContextMenu}
      >
        {!props.getIsColumnHidden?.('name') && (
          <div className={s.folderNodeRow}>
            <Tooltip
              position={PopoverPosition.TOP}
              className={s.tooltipWrapper}
              content={displayName}
            >
              <span className={s.folderName}>
                <Icon className={s.folderNameIcon} icon={icon} key={name} />
                <span className={s.folderNameName}>{displayName}</span>
              </span>
            </Tooltip>
          </div>
        )}
        {props.simplifiedExplorer ? null : (
          <>
            {props.getIsColumnHidden?.('size') ? null : (
              <div className={[s.folderNodeRow, s.informationRow].join(' ')}>
                {size ?? '-'}
              </div>
            )}
            {props.getIsColumnHidden?.('last modified') ? null : (
              <div className={[s.folderNodeRow, s.informationRow].join(' ')}>
                {lastModified ? (
                  <Tooltip
                    content={moment(lastModified).format('HH:mm:ss')}
                    position={Position.TOP}
                  >
                    <span className={s.lastModifiedWrapper}>
                      {moment(lastModified).format('YYYY-MM-DD')}
                    </span>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </div>
            )}
          </>
        )}
      </div>
    </li>
  );
};

export default FolderNodeTree;
