import moment from 'moment';
import { IconNames } from '@blueprintjs/icons';
import {
  AnchorButton,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { DATETIME_FORMAT } from 'constants/datetime';
import GSSchedulingTable from './GSSchedulingTable';
import { durationToReadableFormat } from 'utils/common/dateUtils';
import { useApiGroundStationService } from 'pages/ops/GSScheduling/context/GroundStationProvider';
import type { PassSlot } from '_api/groundStation/types';

import s from '../../index.module.scss';
import { flatten } from 'lodash';

interface IProps {
  isFetching: boolean;
}

const toFormattedDate = (date: string | Date) =>
  moment.utc(date).format(DATETIME_FORMAT);

const GSSchedulingList = (props: IProps) => {
  const {
    passSlots,
    chosenStationIdList,
    groundStations,
    openModalAndSetOpportunityInView,
  } = useApiGroundStationService();

  const renderBookButton = (item: PassSlot) => {
    if (item.status === 'BOOKED') {
      return <AnchorButton text="Booked" intent={Intent.SUCCESS} disabled />;
    }

    const hasPassed = new Date(item.boundaries.end) <= new Date();
    if (hasPassed) {
      return (
        <Tooltip content="Cannot book pass after it has ended">
          <AnchorButton text="View" disabled />
        </Tooltip>
      );
    }

    return (
      <AnchorButton
        text="View"
        onClick={() => openModalAndSetOpportunityInView(item)}
      />
    );
  };

  if (props.isFetching)
    return (
      <div className={s.tableSpinner}>
        <Spinner />
      </div>
    );

  if (chosenStationIdList && !chosenStationIdList.length)
    return (
      <div className={s.schedulingListTableContainer}>
        <NonIdealState
          icon={IconNames.CELL_TOWER}
          title="No ground station selected"
          description="You can select one from the top left of the page"
        />
      </div>
    );

  if (!passSlots)
    return (
      <div className={s.schedulingListTableContainer}>
        <NonIdealState
          icon={IconNames.CELL_TOWER}
          title="Could not retrieve ground station passes"
          description="Try again later or contact the team for help"
        />
      </div>
    );

  if (!passSlots.length)
    return (
      <div className={s.schedulingListTableContainer}>
        <NonIdealState
          icon={IconNames.CELL_TOWER}
          title="No pass slots available"
          description="Try selecting a different time range above or refreshing"
        />
      </div>
    );

  return (
    <>
      <GSSchedulingTable
        isFetching={props.isFetching}
        headerList={[
          {
            display: 'From (UTC)',
          },
          {
            display: 'To (UTC)',
          },
          {
            display: 'Duration',
          },
          {
            display: 'Site',
          },

          {
            display: 'Max elevation (°)',
          },
          {
            display: 'Max availability',
          },
          {
            display: '',
          },
        ]}
        tableData={
          passSlots.map((item) => ({
            start: toFormattedDate(item.boundaries.start),
            end: toFormattedDate(item.boundaries.end),
            duration: {
              toFormat: durationToReadableFormat,
              data: item.maxDuration,
            },
            site: groundStations?.find(
              (groundStation) => groundStation.id === item.site
            )?.name,
            maxElevation: item.maxElevation.toFixed(2),
            maxAvailability: {
              toFormat: durationToReadableFormat,
              data: Math.max(
                ...(flatten(
                  item.antennas.map((a) =>
                    a.availabilities.map((av) => {
                      const start =
                        moment.utc(av.start).unix() >
                        moment.utc(a.uplink.boundaries.start).unix()
                          ? moment.utc(av.start)
                          : moment.utc(a.uplink.boundaries.start);

                      const end =
                        moment.utc(av.end).unix() <
                        moment.utc(a.uplink.boundaries.end).unix()
                          ? moment.utc(av.end)
                          : moment.utc(a.uplink.boundaries.end);

                      return end.unix() - start.unix();
                    })
                  )
                ) || null)
              ),
              isAvailability: true,
            },
            bookButton: (
              <div className={s.tableButtonContainer}>
                {renderBookButton(item)}
              </div>
            ),
          })) as unknown as PassSlot[]
        }
        bodyList={[
          'start',
          'end',
          'duration',
          'site',
          'maxElevation',
          'maxAvailability',
          'bookButton',
        ]}
      />
    </>
  );
};

export default GSSchedulingList;
