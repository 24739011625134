import React, { useCallback, useEffect, useMemo, useState } from 'react';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import ColorControl from 'datacosmos/components/Layers/CommonLayerControls/ColorControl';
import { Button, Input, ListBoxItem, Select } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { PointLayer } from 'datacosmos/entities/pointLayer';
import { usePreviousState } from 'utils/hooks/usePreviousState';
import { postSupervisedClassification } from '_api/sampling/service';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { isGeoreferencedWithGCP } from 'datacosmos/utils/stac';

type Props = {
  app: IApplication;
};

export const SupervisedClassificationApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate(
    'datacosmos.applications.supervisedClassification.title'
  ),
  description: clientTranslate(
    'datacosmos.applications.supervisedClassification.description'
  ),
  inputs: [
    {
      example: '',
      field: 'tableRows',
    },
    {
      field: 'imageId',
      example: '',
    },
    {
      field: 'algorithm',
      example: '',
    },
  ],
  values: {
    tableRows: {
      value: [],
      isError: false,
      message: '',
    },
    imageId: {
      value: '',
      isError: false,
      message: '',
    },
    algorithm: {
      value: '',
      isError: false,
      message: '',
    },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.supervisedClassification.shortDescription'
  ),
  renderer: (app: IApplication) => <SupervisedClassification app={app} />,
  appScreenshotUrl: '',
  tags: [],
};

type NameColorRow = {
  id: string;
  name: string;
  color: string;
  points: { lat: number; lng: number }[];
};

const getDefaultRow = () => {
  return {
    id: '0',
    name: '',
    color: '#' + Math.floor(Math.random() * 16777215).toString(16),
    points: [],
  };
};

const SupervisedClassification = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { removeLayersBySourceType } = useMapLayers();

  const { addLayer, removeLayer, layers } = useMapLayers();

  const { translate } = useLocalisation();

  const { clickedStacLayer, clickedPoint } = useClickedStacItem({
    outlineClickedItem: false,
    onImageClick: (img) => {
      const isNotValidImage = isGeoreferencedWithGCP(img.item);
      setInputData(app.name, {
        ...app.values,
        imageId: {
          value: img.item.id,
          isError: isNotValidImage,
          message: isNotValidImage
            ? translate(
                'datacosmos.applications.global.errors.isGeoreferencedWithGCP'
              )
            : '',
        },
      });
    },
  });

  const { currentScenario, fetchCurrentProjectItems } = useProjects();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [nameColorRow, setNameColorRow] = useState<NameColorRow[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setError = (key: string, message: string) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value: '', isError: true, message: message },
    });
  };

  const pointLayers = useMemo(
    () =>
      layers.filter(
        (layer) => layer.sourceType === LayerSourceType.CLASSIFICATION_MARKER
      ) as PointLayer[],
    [layers]
  );

  const previousClickedPoint = usePreviousState(clickedPoint);

  const markClickedPoint = useCallback(() => {
    if (nameColorRow.length === 0) {
      return;
    }

    if (!clickedPoint) {
      return;
    }

    if (
      clickedPoint.lat === previousClickedPoint?.lat &&
      clickedPoint.lng === previousClickedPoint?.lng
    ) {
      return;
    }

    const marker = new PointLayer(
      LayerSourceType.CLASSIFICATION_MARKER,
      nameColorRow[nameColorRow.length - 1].id,
      [clickedPoint?.lng, clickedPoint?.lat],
      { color: nameColorRow[nameColorRow.length - 1].color }
    );

    setNameColorRow((prev) => {
      const withPoints = [...prev];
      withPoints[withPoints.length - 1].points.push(clickedPoint);

      setValue('tableRows', withPoints);

      return withPoints;
    });

    addLayer(marker);
  }, [
    addLayer,
    clickedPoint,
    nameColorRow,
    previousClickedPoint?.lat,
    previousClickedPoint?.lng,
    setValue,
  ]);

  const handleRemoveClass = useCallback(
    (index: number) => {
      const classToRemove = nameColorRow[index];
      setNameColorRow(nameColorRow.filter((_, i) => i !== index));

      const layersToRemove = pointLayers.filter(
        (layer) => layer.name === classToRemove.id
      );

      layersToRemove.forEach((layer) => {
        removeLayer(layer.id);
      });

      setValue(
        'tableRows',
        nameColorRow.filter((_, i) => i !== index)
      );
    },
    [nameColorRow, pointLayers, removeLayer, setValue]
  );

  const handleAddRow = useCallback(() => {
    const newRows = [
      ...nameColorRow,
      {
        ...getDefaultRow(),
        id: `${nameColorRow.length}`,
      },
    ];
    setNameColorRow(newRows);
    setValue('tableRows', newRows);
  }, [nameColorRow, setValue]);

  const handleCellChange = useCallback(
    (
      index: number,
      field: keyof Omit<NameColorRow, 'points'>,
      value: string
    ) => {
      const updatedData = [...nameColorRow];
      updatedData[index][field] = value;
      setValue('tableRows', updatedData);
      setNameColorRow(updatedData);
    },
    [nameColorRow, setValue]
  );

  const checkNameDuplicates = () => {
    const names = nameColorRow.map((row) => row.name);
    return new Set(names).size !== names.length;
  };

  useEffect(() => {
    markClickedPoint();
  }, [markClickedPoint]);

  useEffect(() => {
    // Remove the layers when the app is closed
    return () => {
      removeLayersBySourceType(LayerSourceType.CLASSIFICATION_MARKER);
    };
  }, [removeLayersBySourceType]);

  const inputs = useCallback(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <span>
            {translate(
              'datacosmos.applications.cropImg.inputs.fullResImg.title'
            )}
          </span>
          <Input
            type="text"
            readOnly
            value={clickedStacLayer?.item.id}
            placeholder={translate(
              'datacosmos.applications.cropImg.inputs.fullResImg.placeholder'
            )}
          />
          {app.values.imageId.isError && (
            <div>
              <small className="text-warning">
                {app.values.imageId.message}
              </small>
            </div>
          )}
        </div>

        <div>
          <span>
            {translate(
              'datacosmos.applications.supervisedClassification.inputs.algorithm.title'
            )}
          </span>
          <Select
            fill
            placeholder={translate(
              'datacosmos.applications.supervisedClassification.inputs.algorithm.placeholder'
            )}
            onSelectionChange={(val) => {
              setValue('algorithm', val);
            }}
            selectedKey={
              app.values.algorithm.value
                ? (app.values.algorithm.value as string)
                : ''
            }
          >
            <ListBoxItem id="mean_squared_error" key="mean_squared_error">
              {translate(
                'datacosmos.applications.supervisedClassification.inputs.algorithm.mean_squared_error'
              )}
            </ListBoxItem>
            <ListBoxItem
              id="support_vector_machine"
              key="support_vector_machine"
            >
              {translate(
                'datacosmos.applications.supervisedClassification.inputs.algorithm.support_vector_machine'
              )}
            </ListBoxItem>
          </Select>
          {app.values.algorithm.isError && (
            <div>
              <small style={{ color: '#ff0000' }}>
                {app.values.algorithm.message}
              </small>
            </div>
          )}
        </div>

        <table>
          <thead>
            <tr>
              <th>
                {translate(
                  'datacosmos.applications.supervisedClassification.inputs.classes.tableTitles.name'
                )}
              </th>
              <th>
                {translate(
                  'datacosmos.applications.supervisedClassification.inputs.classes.tableTitles.color'
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {nameColorRow.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <Input
                    type="text"
                    value={row.name}
                    onChange={(e) =>
                      handleCellChange(index, 'name', e.target.value)
                    }
                    //TODO: Translate
                    placeholder={translate(
                      'datacosmos.applications.supervisedClassification.inputs.classes.placeholders.name'
                    )}
                    onClick={() => {}}
                  />
                </td>
                <td className="flex items-center justify-center w-full">
                  <ColorControl
                    disabled={false}
                    key={row.name}
                    color={row.color}
                    onChange={(c) => {
                      handleCellChange(index, 'color', c);
                    }}
                  />
                </td>
                <td>
                  <Button
                    icon="Trash"
                    onPress={() => {
                      handleRemoveClass(index);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {app.values.tableRows.isError && (
          <div>
            <small className="text-warning">
              {app.values.tableRows.message}
            </small>
          </div>
        )}

        <Button
          icon="Plus"
          onPress={() => {
            handleAddRow();
          }}
          isDisabled={nameColorRow.some((r) => r.name === '')}
        >
          {translate(
            'datacosmos.applications.supervisedClassification.inputs.classes.buttons.addClass'
          )}
        </Button>
      </div>
    );
  }, [
    app.values.imageId.isError,
    app.values.imageId.message,
    app.values.tableRows.isError,
    app.values.tableRows.message,
    clickedStacLayer?.item.id,
    handleAddRow,
    handleCellChange,
    handleRemoveClass,
    nameColorRow,
    translate,
  ]);

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (nameColorRow.length === 0) {
            setError(
              'tableRows',
              translate(
                'datacosmos.applications.supervisedClassification.errors.noClasses'
              )
            );
            return;
          }

          if (nameColorRow.some((r) => r.name === '')) {
            setError(
              'tableRows',
              translate(
                'datacosmos.applications.supervisedClassification.errors.missingNames'
              )
            );
            return;
          }

          const hasDuplicateNames = checkNameDuplicates();

          if (hasDuplicateNames) {
            setError(
              'tableRows',
              translate(
                'datacosmos.applications.supervisedClassification.errors.notUnique'
              )
            );
            return;
          }

          setIsSubmitting(true);
          await postSupervisedClassification({
            params: {
              collection: clickedStacLayer?.item.collection ?? '',
              items: clickedStacLayer?.item.id ?? '',
            },
            body: {
              project_id: currentScenario?.id ?? '',
              categories: {
                type: 'FeatureCollection',
                features: nameColorRow.map((row) => ({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [row.points[0].lng, row.points[0].lat],
                  },
                  properties: {
                    category: row.name,
                    colour: row.color,
                  },
                })),
              },
              algorithm: app.values.algorithm.value as string,
            },
          });
          setIsSubmitting(false);

          await fetchCurrentProjectItems();
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmitting}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default SupervisedClassification;
