import { Classes, MenuItem, PopoverPosition } from '@blueprintjs/core';
import { IconNames as BpIcons } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import { SATELLITE_DATA_TYPE_LIST } from '_api/satellites/types';
import type { SatelliteDataType } from '_api/satellites/types';
import DetailedTooltip from '_molecules/DetailedTooltip/DetailedTooltip';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import s from './InstrumentFilter.module.scss';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, Popover, DialogTrigger, Icon } from 'opencosmos-ui';
import IconButton from '_molecules/IconButton/IconButton';
import { Dialog } from 'react-aria-components';
import { zIndexValues } from 'opencosmos-ui/constants';

type InstrumentFiltersProps = {
  selectedDataTypes: SatelliteDataType[];
  gsd: { min: number; max: number } | null;
  setGsd: (gsd: { min: number; max: number } | null) => void;
  handleDataTypeSelect: (item: SatelliteDataType) => void;
  handleDataTypeRemove: (item: SatelliteDataType) => void;
  getActiveFiltersCount: () => number;
};

export const InstrumentFilters = ({
  selectedDataTypes,
  gsd,
  setGsd,
  handleDataTypeRemove,
  handleDataTypeSelect,
  getActiveFiltersCount,
}: InstrumentFiltersProps) => {
  const DataTypeMultiselect = MultiSelect.ofType<SatelliteDataType>();

  const dataTypeRenderer: ItemRenderer<SatelliteDataType> = (
    dataType,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const selected = selectedDataTypes.includes(dataType);

    return (
      <MenuItem
        onClick={handleClick}
        text={dataType}
        key={dataType}
        icon={selected ? BpIcons.TICK : null}
      />
    );
  };

  const { translate } = useLocalisation();

  return (
    <DialogTrigger>
      <Button size="sm" isMinimal isTransparent>
        <div className="flex items-center gap-1">
          <Icon icon="Filter" />
          <span>
            {translate('datacosmos.tasking.new.filters.filterStatellites')}
          </span>

          {getActiveFiltersCount() !== 0 && (
            <span className="rounded-full bg-surface dark:bg-item-dark dark:text-item-dark-contrast text-xs  h-4 w-4 text-center border-[1px] border-item-contrast">
              {getActiveFiltersCount()}
            </span>
          )}
        </div>
      </Button>

      <Popover placement="right top" className={`!z-[${zIndexValues.popover}]`}>
        <Dialog>
          <div className="bg-item flex flex-col gap-2 p-1 dark:bg-item-dark dark:text-item-dark-contrast">
            <div className="p-1 border-b-2 border-b-contrast-inactive">
              <span> {translate('datacosmos.tasking.new.filters.title')}</span>
            </div>

            <div>
              <h6
                className={
                  Classes.HEADING +
                  ' ' +
                  s.filterHeading +
                  ' dark:bg-item-dark dark:text-item-dark-contrast'
                }
              >
                <Icon icon="data-lineage" size={16} />
                <span>
                  {translate(
                    'datacosmos.tasking.new.instruments.filters.dataType'
                  )}
                </span>
              </h6>
              <DataTypeMultiselect
                fill
                itemRenderer={dataTypeRenderer}
                items={Object.values(SATELLITE_DATA_TYPE_LIST)}
                onItemSelect={handleDataTypeSelect}
                onRemove={handleDataTypeRemove}
                selectedItems={selectedDataTypes}
                tagRenderer={(tag) => tag}
                tagInputProps={{
                  className: 'dark:bg-item-dark dark:text-item-dark-contrast',
                  tagProps: {
                    onClick: (e) => e.stopPropagation(),
                    className:
                      'dark:bg-item-dark dark:text-item-dark-contrast m-0 p-0 focus:dark:bg-item-dark-hover border-2 border-item-contrast-text-field-stroke',
                  },
                  inputProps: {
                    className:
                      'dark:bg-item-dark dark:text-item-dark-contrast p-0 focus:dark:bg-item-dark-hover border-2 border-item-contrast-text-field-stroke',
                  },
                }}
                popoverProps={{
                  position: PopoverPosition.LEFT,
                  fill: true,
                  className: 'dark:bg-item-dark dark:text-item-dark-contrast',
                }}
                className="dark:bg-item-dark dark:text-item-dark-contrast focus:dark:bg-item-dark-hover border-2 border-item-contrast-text-field-stroke"
              />
            </div>

            <div className="flex-col w-full p-2">
              <div className="flex items-center w-full">
                <div className="flex items-center w-full gap-4">
                  <Icon icon="Satellite" />
                  <DetailedTooltip
                    delay={0}
                    content={translate('datacosmos.tooltips.tasking.gsd.title')}
                    description={translate(
                      'datacosmos.tooltips.tasking.gsd.description'
                    )}
                  >
                    <span>
                      {translate(
                        'datacosmos.tasking.new.instruments.filters.gsd'
                      )}
                    </span>
                  </DetailedTooltip>
                </div>
                <IconButton
                  icon={gsd ? 'Trash' : 'PlusSquare'}
                  onPress={() => {
                    if (gsd) {
                      setGsd(null);
                    } else {
                      setGsd({ min: 0, max: 10 });
                    }
                  }}
                  data-testid="reset-oza-filter-btn"
                />
              </div>
              {gsd && (
                <RangeSlider
                  numberOfHandles={2}
                  minValue={0}
                  maxValue={10}
                  step={0.5}
                  showValuesAboveHandles
                  showScale={true}
                  value={[gsd.min, gsd.max]}
                  onChange={(val) => setGsd({ min: val[0], max: val[1] })}
                  handleLabelFormatter={(val: number) => {
                    if (val === 10) {
                      return '>10m';
                    }
                    return val.toString() + 'm';
                  }}
                />
              )}
            </div>
          </div>
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
};
