import { useEffect, useMemo, useState } from 'react';
import { useComposedState } from 'utils/hooks/useComposedState';
import { useQuery } from '_api/useQuery';
import { getViews } from '_api/views/service';
import { getScenarios } from '_api/scenarios/service';
import { now, toFormattedDate } from 'utils/common/dateUtils';
import type { CardTag } from 'ui/Card';
import { screenshotLinkToUsableImage } from 'utils/screenshot';

import placeholderImage from 'images/datacosmos/img-placeholder.svg';

export type DateRange = {
  min?: Date;
  max?: Date;
};

/*
 * There are a different date states for the `created` and `updated` cases to preserve
 * the previous selection when switching between them.
 *
 * This is more user friendly because it avoids setting a date that is not valid
 * in the limit of the min/max range of the new selection.
 *
 * It's verbose to have to manually define each posible state, so if in the future
 * we need to add more options this could be refactored to be handled automatically.
 */
export type ViewsFilter = {
  orderBy: string;
  sortOrder: string;
  updatedFromDate: Date;
  updatedToDate: Date;
  updatedRange?: DateRange;
  createdFromDate: Date;
  createdToDate: Date;
  createdRange?: DateRange;
  project: string;
  search: string;
};

export type ViewsData = {
  image: string;
  title: string;
  description: string;
  url?: string;
  project?: string;
  createdAt?: Date;
  updatedAt?: Date;
  tags?: CardTag[];
};

const DEFAULT_PROJECT = 'All';

const UPDATED = 'Updated';
const CREATED = 'Created';
const sortByOptions = [UPDATED, CREATED];

const ASCENDING = 'Ascending';
const DESCENDING = 'Descending';
const sortOrderOptions = [ASCENDING, DESCENDING];

export const useViewsData = () => {
  const viewsQuery = useQuery(getViews, { initialData: [] });
  const projectsQuery = useQuery(getScenarios, { initialData: [] });

  const [views, setViews] = useState<ViewsData[]>([]);

  const [filter, setFilter, setFilterState] = useComposedState<ViewsFilter>({
    orderBy: UPDATED,
    sortOrder: ASCENDING,
    updatedFromDate: now(),
    updatedToDate: now(),
    createdFromDate: now(),
    createdToDate: now(),
    project: DEFAULT_PROJECT,
    search: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const updatedRange = { min: now(), max: now() };
      const createdRange = { min: now(), max: now() };
      const mappedViewsPromises = viewsQuery.data.map(async (view) => {
        const updatedAt = new Date(view.updated_at);
        if (updatedAt.getTime() < updatedRange.min.getTime()) {
          updatedRange.min = updatedAt;
        }
        if (updatedAt.getTime() > updatedRange.max.getTime()) {
          updatedRange.max = updatedAt;
        }
        const createdAt = new Date(view.created_at);
        if (createdAt.getTime() < createdRange.min.getTime()) {
          createdRange.min = createdAt;
        }
        if (createdAt.getTime() > createdRange.max.getTime()) {
          createdRange.max = createdAt;
        }

        const img = await screenshotLinkToUsableImage(view.screenshot_link);

        return {
          title: view.name,
          description: view.description,
          project: view.project,
          createdAt,
          updatedAt,
          url: `/data/project/${view.project}/items?view=${view.id}&mode=minimal`,
          image: img ?? placeholderImage,
        };
      });

      setFilterState((prev) => ({
        ...prev,
        updatedRange,
        updatedFromDate: updatedRange.min,
        updatedToDate: updatedRange.max,
        createdRange,
        createdFromDate: createdRange.min,
        createdToDate: createdRange.max,
      }));

      const mappedViews = await Promise.all(mappedViewsPromises);

      setViews(mappedViews);
    };

    void fetchData();
  }, [setFilterState, viewsQuery.data]);

  type Project = { [key: string]: string };
  const projects = useMemo(() => {
    return projectsQuery.data.reduce<Project>((acc, project) => {
      if (project.id && project.title) {
        acc[project.id] = project.title;
      }
      return acc;
    }, {});
  }, [projectsQuery.data]);

  const projectOptions = useMemo(() => {
    const availableProjects = views.map(({ project }) => project);
    return Object.keys(projects).reduce(
      (acc, projectId) => {
        if (availableProjects.includes(projectId)) {
          acc.push(projects[projectId]);
        }
        return acc;
      },
      [DEFAULT_PROJECT]
    );
  }, [projects, views]);

  const date = useMemo(() => {
    if (filter.orderBy === CREATED) {
      return {
        from: filter.createdFromDate,
        setFrom: setFilter.createdFromDate,
        to: filter.createdToDate,
        setTo: setFilter.createdToDate,
        range: filter.createdRange,
      };
    }
    return {
      from: filter.updatedFromDate,
      setFrom: setFilter.updatedFromDate,
      to: filter.updatedToDate,
      setTo: setFilter.updatedToDate,
      range: filter.updatedRange,
    };
  }, [filter, setFilter]);

  const sortedViews = useMemo(() => {
    return views
      .map((view) => {
        const tags: CardTag[] = [];
        if (view.project && projects[view.project]) {
          tags.push({
            title: projects[view.project],
            icon: 'Features',
          });
        }
        if (view.updatedAt) {
          tags.push({
            title: `Updated at ${toFormattedDate(view.updatedAt)}`,
          });
        }
        if (view.createdAt) {
          tags.push({
            title: `Created at ${toFormattedDate(view.createdAt)}`,
          });
        }
        return { ...view, tags };
      })
      .filter((view) => {
        const viewDate =
          filter.orderBy === CREATED
            ? view.createdAt?.getTime()
            : view.updatedAt?.getTime();

        if (
          viewDate &&
          (viewDate < date.from.getTime() || viewDate > date.to.getTime())
        ) {
          return false;
        }

        if (filter.project !== DEFAULT_PROJECT) {
          if (!view.project) return false;
          if (projects[view.project] !== filter.project) return false;
        }

        if (filter.search) {
          return `${view.title}${view.description}`
            .toLowerCase()
            .includes(filter.search.toLowerCase());
        }

        return true;
      })
      .sort((a, b) => {
        const aDate = filter.orderBy === UPDATED ? a.updatedAt : a.createdAt;
        const bDate = filter.orderBy === UPDATED ? b.updatedAt : b.createdAt;
        if (!aDate || !bDate) return 0;
        return filter.sortOrder === ASCENDING
          ? bDate.getTime() - aDate.getTime()
          : aDate.getTime() - bDate.getTime();
      });
  }, [date, filter, projects, views]);

  return {
    loading: viewsQuery.loading || projectsQuery.loading,
    filter,
    setFilter,
    date,
    views: sortedViews,
    sortByOptions,
    sortOrderOptions,
    projectOptions,
  };
};
