import Button from '_molecules/Button/Button';
import classNames from 'classnames';
import type { AriaCheckboxProps } from '@react-types/checkbox';
import type { PressEvent } from '@react-types/shared';
import type { IconName } from 'ui/Icon';
import type { Order } from '_api/orders/types';
import moment from 'moment';
import { DATETIME_FORMAT } from 'constants/datetime';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import DangerButton from '_molecules/Button/DangerButton';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import { Checkbox } from 'opencosmos-ui';
import { useTheme } from 'datacosmos/stores/ThemeProvider';

type FieldsToShow = 'summary' | 'status' | 'access' | 'all';

type Props = {
  /**
   * The order to show on the card. Contains all the information about the order. Is of type `Order`
   */
  order: Order;
  /**
   * Order specific fields to show on the card. Defaults to all fields
   */
  fieldsToShow: FieldsToShow[];
  /**
   * The function to call when the card is clicked
   */
  onClick: () => void;
  /**
   * Whether the card is selected or not. Defaults to false
   */
  isSelected?: boolean;
  /**
   * The checkbox to show on the left side of the card
   */
  checkbox?: AriaCheckboxProps;
  /**
   * The action button to show on the right side of the card
   */
  actionButton?: {
    onPress: (e: PressEvent) => void;
    text: string;
    icon?: IconName;
    disabled?: boolean;
    intent?: 'primary' | 'danger';
  };
  /**
   * Total cost of the order
   */
  orderCost?: string;
};

/**
 * The OrderCard component is used to display an order in a list of orders. To be mainly used in the OrdersScreen.
 * Contains information about the order, such as the order summary, order status and access status.
 * Can be used with or without a checkbox and/or an action button.
 */
const OrderCard = ({
  checkbox,
  actionButton,
  fieldsToShow = ['all'],
  order,
  onClick,
  isSelected,
  orderCost,
}: Props) => {
  const themeProvider = useTheme();
  const orderSummary = (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
        {clientTranslate('datacosmos.orders.orderSummary.title')}
      </span>
      <span className="text-item-contrast dark:text-item-dark-contrast">
        {order.type === 'IMAGE'
          ? clientTranslate('datacosmos.orders.orderSummary.description', {
              count: order.order_line_items.length,
            })
          : clientTranslate('datacosmos.orders.orderSummary.taskingTitle')}
      </span>
    </div>
  );

  const orderStatus = (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
        {clientTranslate('datacosmos.orders.orderStatus.title')}
      </span>
      <span className="text-item-contrast dark:text-item-dark-contrast">
        {clientTranslate(`datacosmos.orders.orderStatus.${order.status}`)}
      </span>
    </div>
  );

  //TODO: Add access status when it becomes available
  const accessStatus = (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
        {clientTranslate('datacosmos.orders.accessStatus.title')}
      </span>
      <span className="text-item-contrast dark:text-item-dark-contrast">-</span>
    </div>
  );

  const orderAmount = (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
        {clientTranslate('datacosmos.orders.orderAmount.title')}
      </span>
      <span className="text-item-contrast dark:text-item-dark-contrast">
        {orderCost}
      </span>
    </div>
  );

  const orderPaymentId = (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
        {clientTranslate('datacosmos.orders.payment.id')}
      </span>
      <span className="text-item-contrast dark:text-item-dark-contrast">
        {order?.payment_reference_number}{' '}
        {order?.external_payment_id && (
          <span className="text-xs">
            ({clientTranslate('datacosmos.orders.payment.userReferenceId')} :{' '}
            {order?.external_payment_id})
          </span>
        )}
      </span>
    </div>
  );

  const orderRelatedFields = [
    {
      name: 'summary',
      display: orderSummary,
    },
    {
      name: 'status',
      display: orderStatus,
    },
    {
      name: 'access',
      display: accessStatus,
    },
    {
      name: 'amount',
      display: orderAmount,
    },
    {
      name: 'paymentId',
      display: orderPaymentId,
    },
  ];

  const getFieldsToDisplay = () => {
    if (fieldsToShow.includes('all')) {
      return orderRelatedFields.map((f) => f.display);
    }

    return orderRelatedFields
      .filter((f) => fieldsToShow.some((fts) => f.name === fts))
      .map((f) => f.display);
  };

  const getActionButton = () => {
    if (!actionButton) {
      return null;
    }

    if (actionButton.intent === 'danger') {
      return <DangerButton {...actionButton} />;
    }

    if (actionButton.intent === 'primary') {
      return <PrimaryButton {...actionButton} />;
    }
    return <Button {...actionButton} />;
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-xs flex items-center justify-between text-item-contrast dark:text-item-dark-contrast">
        <span>ID {order.id}</span>
        <span>
          {clientTranslate('datacosmos.orders.orderedOn', {
            date: moment(order.created_at).format(DATETIME_FORMAT),
          })}
        </span>
      </div>
      <div
        data-testid="order-card"
        onClick={() => onClick()}
        className={classNames(
          'grid !shadow-none dark:bg-item-dark bg-item min-h-16 p-2 border cursor-pointer',
          {
            'grid-cols-[0.3fr_1fr_1fr_1fr_1fr_1fr_1fr]':
              checkbox && actionButton,
            'grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr]': !checkbox && actionButton,
            'grid-cols-[0.3fr_1fr_1fr_1fr_1fr_1fr]': checkbox && !actionButton,
            'grid-cols-[1fr_1fr_1fr_1fr_1fr]': !checkbox && !actionButton,
            'border-item-contrast dark:border-item-dark-contrast': isSelected,
            'border-item dark:border-item-dark': !isSelected,
          }
        )}
      >
        {checkbox && (
          <div className="flex items-center ml-auto mr-auto">
            <Checkbox {...checkbox} themeProvider={themeProvider} />
          </div>
        )}
        {getFieldsToDisplay()}
        <div
          className="flex items-center justify-end"
          style={{
            gridColumn: 'auto / -1',
          }}
        >
          {getActionButton()}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
