import React from "react";
import { GroupedNavItems, NavItem } from "./types";
import classNames from "classnames";
import SidebarGroup from "./SidebarGroup";

type Props = {
  navItems: GroupedNavItems;
  navFooterItems?: GroupedNavItems;
  collapsedGroups?: string[];
  isCollapsed?: boolean;
  selectedItemKey?: string;
};

const Sidebar = ({
  navItems,
  navFooterItems,
  collapsedGroups,
  isCollapsed,
  selectedItemKey,
}: Props) => {
  const [hiddenGroupKeys, setHiddenGroupKeys] = React.useState<string[]>(
    collapsedGroups ?? []
  );

  const toggleGroupHidden = (group: string) => {
    setHiddenGroupKeys(
      hiddenGroupKeys.includes(group)
        ? hiddenGroupKeys.filter((item) => item !== group)
        : [...hiddenGroupKeys, group]
    );
  };

  return (
    <nav
      className={classNames({
        "w-1/6 md:text-sm lg:text-base bg-header dark:bg-header-dark flex flex-col gap-2 overflow-y-auto py-1 flex-grow":
          !isCollapsed,
        // Arbitrary width to match the topbar height
        "w-[50px] bg-header dark:bg-header-dark flex flex-col py-1 flex-grow":
          isCollapsed,
      })}
    >
      <SidebarGroup
        groupedNavItems={navItems}
        collapsedItems={hiddenGroupKeys}
        toggleGroupHidden={toggleGroupHidden}
        isCollapsed={isCollapsed}
        selectedItemKey={selectedItemKey}
      />
      <div className="flex-1" />
      {navFooterItems && (
        <SidebarGroup
          groupedNavItems={navFooterItems}
          collapsedItems={hiddenGroupKeys}
          toggleGroupHidden={toggleGroupHidden}
          isCollapsed={isCollapsed}
          selectedItemKey={selectedItemKey}
        />
      )}
    </nav>
  );
};

export default Sidebar;
