import classNames from 'classnames';
import React, { useRef } from 'react';
import type { IconName, IconSize } from '_atoms/Icon/Icon';
import Icon from '_atoms/Icon/Icon';
import { useButton } from 'react-aria';
import type { PressEvent } from '@react-types/shared';
import { Tooltip } from 'opencosmos-ui';

export type IconButtonProps = {
  active?: boolean;
  'data-testid'?: string;
  disabled?: boolean;
  icon: IconName;
  size?: IconSize;
  stroke?: string;
  inherit?: boolean;
  className?: string;
  iconClassName?: string;
  tooltipText?: string;
  onPress?: (e: PressEvent) => void;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  'aria-label'?: string; //TODO: since this button does not have text, should the label be mandatory?
};

export const IconButton = (props: IconButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      'aria-label': props['aria-label'] ?? props.icon,
      isDisabled: props.disabled,
      onPress: props.onPress,
    },
    ref
  );

  return (
    <button
      {...buttonProps}
      className={classNames('flex flex-col justify-center', props.className)}
      data-testid={props['data-testid']}
      disabled={props.disabled}
      style={props.style}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Tooltip
        content={props.tooltipText ?? ''}
        isDisabled={!props.tooltipText?.length}
      >
        <div className="flex flex-col justify-center h-full">
          <Icon
            icon={props.icon}
            size={props.size}
            stroke={props.stroke}
            inherit={props.inherit}
            className={classNames(
              'stroke-item-contrast dark:stroke-item-dark-contrast',
              {
                'cursor-pointer': !props.disabled,
                'stroke-accent-900 dark:stroke-accent-400': props.active,
                'stroke-contrast-inactive fill-contrast-inactive':
                  props.disabled,
                'hover:stroke-accent-950 hover:dark:stroke-accent-200':
                  !props.disabled,
              },
              props.iconClassName
            )}
          />
        </div>
      </Tooltip>
    </button>
  );
};

export default IconButton;
