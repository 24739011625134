import React, { useState } from 'react';
import {
  remove,
  startCase,
  map,
  reduce,
  filter,
  pipe,
  uniq,
  isEmpty,
  difference,
} from 'lodash/fp';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  Collapse,
  Icon,
  Intent,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { intersection } from 'lodash';
import { IconNames } from '@blueprintjs/icons';

import TagHeader from 'components/common/TagHeader';

import s from '../../index.module.scss';
import type { GS } from '_api/groundStation/types';

interface IProps {
  groundStations: GS[];
  currentFocusId: number;
  currentStationIdList: number[];
  currentZoom: number;
  setCurrentFocusId: (id: number) => void;
  handleGSDetailZoomClick: (stationId: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleProviderCheckBoxChange: (id: any, provider: string) => void;
  handleGSCheckBoxChange: (id: number | string) => void;
}

const combineStationByProvider = (stationList: GS[]) => {
  return pipe(
    map('providerName'),
    uniq,
    reduce((acc, provider) => {
      const stationByProvider = filter(['providerName', provider], stationList);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return [...acc, { provider, stations: stationByProvider }];
    }, [] as { provider: string; stations: GS[] }[])
  )(stationList) as { provider: string; stations: GS[] }[];
};

export const isAllCheckboxActive = (
  stations: GS[],
  stationIdList: number[]
) => {
  if (isEmpty(stationIdList)) {
    return false;
  }

  return pipe(
    map('id'),
    (ids) => difference(ids, stationIdList) as number[],
    isEmpty
  )(stations);
};

export const isAnyCheckboxActive = (
  stations: GS[],
  stationIdList: number[],
  provider: string
) => {
  const isAllActive = isAllCheckboxActive(stations, stationIdList);

  const relevantStations = stations.filter(
    (station) => station.providerName === provider
  );

  const containsStationsFromThisProvider = !pipe(
    map('id'),
    (ids: number[]) => intersection(ids, stationIdList) as number[],
    isEmpty
  )(relevantStations);

  return !isEmpty(stationIdList) || isAllActive
    ? containsStationsFromThisProvider
    : false;
};

export const getIndeterminateState = (
  stations: GS[],
  stationIdList: number[],
  provider: string
) => {
  return (
    isAnyCheckboxActive(stations, stationIdList, provider) &&
    !isAllCheckboxActive(stations, stationIdList)
  );
};

const GSList = (props: IProps) => {
  const [openList, setOpenList] = useState<string[]>([]);

  const checkIfOpen = (providerName: string) => {
    return openList.includes(providerName);
  };

  const handleItemClick = (provider: string) => {
    if (openList.includes(provider)) {
      const newItems = remove((it) => it === provider, openList);
      setOpenList([...newItems]);
      return;
    }
    setOpenList([...openList, provider]);
  };

  return (
    <div className={s.gslistContainer}>
      <TagHeader
        icon="globe"
        intent={Intent.PRIMARY}
        title="Ground Stations Network"
      />
      <div className={s.gslistWrapper}>
        <ul className={Classes.LIST}>
          {combineStationByProvider(props.groundStations).map(
            (item: { provider: string; stations: GS[] }) => (
              <li
                role="presentation"
                key={item.provider as string}
                className={s.selectedItem}
              >
                <div className={s.itemWrapper}>
                  <div
                    role="presentation"
                    className={[Classes.MENU_ITEM, s.providerTitle].join(' ')}
                    onClick={() => handleItemClick(item.provider)}
                  >
                    <div>
                      <Checkbox
                        indeterminate={getIndeterminateState(
                          item.stations,
                          props.currentStationIdList,
                          item.provider
                        )}
                        checked={isAllCheckboxActive(
                          item.stations,
                          props.currentStationIdList
                        )}
                        onChange={() =>
                          props.handleProviderCheckBoxChange(
                            item.stations,
                            item.provider
                          )
                        }
                      />
                      {startCase(item.provider)}
                    </div>
                    <Icon
                      className={s.providerIcon}
                      icon={
                        checkIfOpen(item.provider)
                          ? 'chevron-down'
                          : 'chevron-right'
                      }
                    />
                  </div>
                  <div className={s.collapseWrapper}>
                    <Collapse isOpen={checkIfOpen(item.provider)}>
                      {item.stations.map((station: GS) => (
                        <div key={station.id} className={s.collapseItemWrapper}>
                          <div className={s.collapseItem}>
                            <div className={s.gsListCheckboxContainer}>
                              <Checkbox
                                key={station.name}
                                inline
                                checked={props.currentStationIdList.includes(
                                  station.id
                                )}
                                onChange={() =>
                                  props.handleGSCheckBoxChange(station.id)
                                }
                              />
                              <span
                                role="presentation"
                                onClick={() =>
                                  props.handleGSCheckBoxChange(station.id)
                                }
                              >
                                {station.name}{' '}
                                <Tooltip
                                  content={`Available antennas: ${station.antennas.length}`}
                                >
                                  <span>[{station.antennas.length}]</span>
                                </Tooltip>
                              </span>
                            </div>
                            <div className={s.gsListButtonContainer}>
                              <ButtonGroup minimal>
                                <Button
                                  minimal
                                  small
                                  icon={IconNames.LOCATE}
                                  onClick={(
                                    e: React.MouseEvent<HTMLElement>
                                  ) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.setCurrentFocusId(station.id);
                                  }}
                                />
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Collapse>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default GSList;
