import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import IconButton from '_molecules/IconButton/IconButton';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import DatePicker from '_molecules/DatePicker/DatePicker';
import { parseDate } from '@internationalized/date';
import { btoaSafe } from 'utils/common/btoaSafe';
import { postTicket } from '_api/tickets/service';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const NaxAgricultureApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'AI-powered agriculture with Nax Solutions',
  description:
    'Nax Solutions combines the power of Artificial' +
    ' Intelligence with Remote Sensing, generating unique models' +
    ' that learn from the crop in order to learn from its behavior.' +
    ' Leading the industry in AI models applied to agriculture,' +
    ' we currently help producers all around the world take decissions' +
    ' for better resource management based on modeling the crop with complex data-science ' +
    ' approaches, that are finally translated into simple user-oriented models: where and' +
    ' how much to fertilize, where and how much to irrigate, yield estimations,' +
    ' field operation monitoring, detection of weeds and anomalies, etc',
  shortDescription:
    'Nax Solutions empowers agriculture: modeling and predicting crop behavior with Satellite data & Artificial Intelligence.',
  provider: {
    name: 'Nax Solutions',
    description: '',
    id: 3,
    url: 'https://www.naxsolutions.com/en/home',
    icon_url: DATACOSMOS_IMG + 'nax_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'nax_app_screenshot.png',
  frequencyOfData: 'Between 1 and 7 images per week',
  price: 'Contact us',
  fee: 'Fee is proportional to the surface monitored',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDate',
      example: '',
    },
    {
      field: 'analysisType',
      example: '',
    },
  ],
  values: {
    AOI: { value: undefined, isError: false, message: '' },
    startDate: { value: undefined, isError: false, message: '' },
    analysisType: { value: undefined, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <NAXAgriculture app={app} />,
  tags: [AppTags.agriculture],
};

const ANALYSIS_TYPES = [
  'Production Estimation',
  'Irrigation Optimisation',
  'Anomaly detection',
];

const NAXAgriculture = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setNaxAOI,
    applicationAOIs: naxAOI,
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();

  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [analysisType, setAnalysisType] = useState<string>();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  useEffect(() => {
    setValue('startDate', startDate);
    setValue('AOI', naxAOI);
    setValue('analysisType', analysisType);
  }, [startDate, naxAOI, analysisType, setValue]);

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setNaxAOI}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setNaxAOI}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setNaxAOI([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(moment(d.toString()).toDate())}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div>
          <Select2
            onSelectionChange={(item) => setAnalysisType(item.toString())}
            fill
            label={<label htmlFor="analysis">Analysis type: </label>}
            selectedItemClassName="border-2 border-item"
          >
            {ANALYSIS_TYPES.map((item) => (
              <Item key={item}>{item}</Item>
            ))}
          </Select2>
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();

    void postTicket({
      body: {
        title: `DataCosmos Subscription to ${app.name}`,
        description: mailBody,
        team: 'datacosmos',
      },
    });
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(a) => {
        setSelectedInstalledApp(a);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
