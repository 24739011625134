import { useEffect } from 'react';
import { PopoverPosition } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import AdministrationRoleSelect from '../../../../shared/components/AdministrationRoleSelect';
import useUser from '../../../../../../services/api/portal/administration/hook/useUser';

import { getUserPicture, getTextForNonStandardUser } from './utils';
import type {
  IRole,
  IRoleAssignment,
} from 'services/api/portal/administration/api/types';

interface IProps {
  currentId: number;
  userId: string;
  roleAssignments: IRoleAssignment[];
  availableRoles: IRole[];
  assignRole?: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments?: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
}

const UserListItem = (props: IProps) => {
  const { user, getUsersById, isFetching } = useUser();

  useEffect(() => {
    void getUsersById(props.userId);
  }, [getUsersById, props.userId]);

  const userPopover = user ? user.email : props.userId;
  const userPicture = getUserPicture(isFetching, props.userId, user);
  const userName = user
    ? user.name
    : getTextForNonStandardUser(isFetching, props.userId);

  return (
    <li className="flex justify-between mb-2">
      <Tooltip position={PopoverPosition.LEFT} content={userPopover}>
        <div className="flex items-center min-w-[320px]">
          {userPicture}
          <span className="text-sm mx-1">{userName}</span>
        </div>
      </Tooltip>
      <AdministrationRoleSelect
        userId={props.userId}
        currentId={props.currentId}
        assignRole={props.assignRole ?? (() => {})}
        deleteRoleAssignments={props.deleteRoleAssignments}
        availableRoles={props.availableRoles}
        currentAssignments={props.roleAssignments}
      />
    </li>
  );
};

export default UserListItem;
