import Loading from 'components/common/Loading';
import React, { useMemo } from 'react';
import { useAuthentication } from 'services/auth/useAuthentication';
import { useAuthorisation } from 'services/auth/useAuthorisation';
import { AuthContext } from 'services/auth/AuthWrapper';
import { newOpencosmosAuthBacked } from './opencosmosAuthBackend';
import ConfigureOrganisation from 'pages/shared/ConfigureOrganisation';

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider = (props: IProps) => {
  const authBackendProvider = useMemo(
    () => () => newOpencosmosAuthBacked(),
    []
  );

  const authentication = useAuthentication(authBackendProvider);
  const authorisation = useAuthorisation(
    authentication.user?.sub,
    authentication.token
  );

  if (authentication.loading || authorisation.loading) {
    return <Loading isLoading={false} />;
  }

  if (authentication.user && !authorisation.isUserPartOfOrganisation) {
    return <ConfigureOrganisation user={authentication.user} />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...authentication,
        ...authorisation,
        loading: false,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
