import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import s from './index.module.scss';
import { Tooltip2 } from '@blueprintjs/popover2';
import type { Alert } from '_api/alerts/types';

interface IProps {
  alert: Alert | undefined;
  silenceHandler: Function;
  muted: boolean;
}

const LibraryAlertsRow = (props: IProps) => {
  const [alert, setAlert] = useState<Alert>();

  useEffect(() => {
    if (props.alert) {
      setAlert(props.alert);
    }
  }, [props.alert]);

  if (!alert) return null;

  return (
    <tr
      key={alert.labels.alertname}
      className={props.muted ? s.inactiveRow : ''}
    >
      <td className={s.tableNames}>{alert.labels.alertname}</td>
      <td className={s.tableAlertValues}>{alert.labels.severity}</td>
      <td className={s.tableAlertButtons}>
        <Tooltip2 content={props.muted ? 'Unmute alert' : 'Mute alert'}>
          <Button
            minimal
            icon={props.muted ? IconNames.VOLUME_UP : IconNames.VOLUME_OFF}
            // @ts-expect-error
            onClick={props.silenceHandler}
          />
        </Tooltip2>
      </td>
    </tr>
  );
};

export default LibraryAlertsRow;
