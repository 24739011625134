import { getBillingPortalURL } from '_api/orders/service';
import { Button } from 'opencosmos-ui';
import { useAuth } from 'services/auth/AuthWrapper';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import useLocalStorage from 'utils/hooks/useLocalStorage';

const SetupBillingPrompt = () => {
  const { translate } = useLocalisation();
  const [currentOrganisation] = useLocalStorage<number | undefined>(
    'currentOrganisation',
    undefined
  );

  const { isUserPartOfOrganisation } = useAuth();
  const handleGoToStripe = async () => {
    if (!currentOrganisation) {
      return;
    }
    const { data } = await getBillingPortalURL({
      params: {
        organisationId: currentOrganisation?.toString(),
      },
    });
    if (data?.portal_link) {
      window.open(data?.portal_link, '_self');
    }
  };

  const handleSkip = () => {
    window.location.href = '/data';
  };

  return (
    <>
      <h4 className="mb-4 font-bold text-lg">
        {translate('onboarding.setupBilling.title')}
      </h4>
      <p className="mb-10">
        {translate('onboarding.setupBilling.description')}
      </p>

      <div className={'flex items-center justify-between gap-3'}>
        <Button onPress={handleSkip} size="base" fill isMinimal>
          {translate('onboarding.setupBilling.buttons.skipForNow')}
        </Button>
        <Button
          intent="primary"
          onPress={handleGoToStripe}
          size="base"
          fill
          className={'text-item-accent-contrast hover:text-item-contrast'}
        >
          {translate('onboarding.setupBilling.buttons.goToStripe')}
        </Button>
      </div>
    </>
  );
};

export default SetupBillingPrompt;
