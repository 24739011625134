export interface IContext {
  limit?: number;
  returned?: number;
  matched?: number;
}

export type ILinkRelationship =
  | 'self'
  | 'root'
  | 'parent'
  | 'collection'
  | 'derived_from'
  | 'alternate'
  | 'next' // franklin only
  | string; // typing glitch

export interface ILink {
  href: string;
  rel: ILinkRelationship;
  type?: string;
  title?: string;
}

export type IAssetRole = 'thumbnail' | 'overview' | 'data' | 'metadata';

export interface IColorMapColor {
  value: number;
  colour: string;
}

export interface IColorMap {
  title?: string;
  units?: string;
  colours: IColorMapColor[];
}

export interface IClassification {
  title?: string;
  color_hint?: string;
  name?: string;
  value: number;
}

export interface IAsset {
  href: string;
  title?: string;
  description?: string;
  type?: string;
  roles?: (IAssetRole | string)[];

  gsd?: number; // only seen in Sentinel-2 data
  application?: string; // only seen in Sentinel-2 data
  profile?: string; // only seen in Sentinel-2 data
  'opencosmos:colourmap'?: IColorMap;
  'classification:classes'?: IClassification[];
}

export type IAssets = Record<string, IAsset>;

export interface IProperties {
  datetime?: string;
  title?: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [additionalProperty: string]: any;
}

export interface IPropertiesWithCommon extends IProperties {
  gsd?: string;
  platform?: string;
}

export interface IDataCosmosProperties extends IPropertiesWithCommon {
  'datacosmos:platform_type'?: string;
  'opencosmos:rid'?: string;
  'opencosmos:copyright'?: string;
  'opencosmos:scale'?: number;
  'opencosmos:resolution'?: string;
  'processing:level'?: string;
  'opencosmos:data_area_km2'?: number;
  'opencosmos:high_resolution_read_permission'?: boolean;
}

export interface IStacItem {
  stac_version: string;
  stac_extensions: string[];
  id: string;
  type: string;
  geometry: GeoJSON.GeoJSON;
  bbox: GeoJSON.BBox;
  properties: IDataCosmosProperties;
  links: ILink[];
  assets: IAssets;
  collection?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  algebraLayerType?: any;
}

export interface ILabel {
  key: string;
  value: string;
}

export interface IStacApiSearch {
  type: 'FeatureCollection';
  features: StacItem[];
  links?: ILink[];
  numberMatched: number;
  numberReturned: number;
}

export interface IScenarioApiSearch {
  features: StacItem[];
  links?: ILink[];
  type: 'FeatureCollection';
  numberMatched: number;
  numberReturned: number;
}

export interface IStacItemGroup {
  [key: string]: StacItem[];
}

export enum AssetType {
  RASTER = 'Raster',
  VECTOR = 'Vector',
}

export enum CustomFilterType {
  ASSET = 'Asset',
  BAND = 'Band',
}

export type BandTypes =
  | 'coastal'
  | 'blue'
  | 'green'
  | 'red'
  | 'yellow'
  | 'pan'
  | 'rededge'
  | 'nir'
  | 'nir08'
  | 'nir09'
  | 'cirrus'
  | 'swir16'
  | 'swir22'
  | 'lwir'
  | 'lwir11'
  | 'lwir12';

export type ImageBandType = {
  [key in BandTypes]: string[];
};

export const IMAGE_BAND: ImageBandType = {
  coastal: ['B01', 'B1', 'coastal'],
  blue: ['B02', 'B2', 'blue', 'B', 'b'],
  green: ['B03', 'B3', 'green', 'G', 'g'],
  red: ['B04', 'B4', 'red', 'R', 'r'],
  yellow: ['yellow'],
  pan: ['PAN', 'B8', 'pan'],
  rededge: ['B05', 'B06', 'B07', 'rededge'],
  nir: ['B08', 'B5', 'nir'],
  nir08: ['B08a', 'nir08'],
  nir09: ['B09', 'nir09'],
  cirrus: ['B10', 'B9', 'cirrus'],
  swir16: ['B11', 'B6', 'swir16'],
  swir22: ['B12', 'B7', 'swir22'],
  lwir: ['lwir'],
  lwir11: ['B10', 'lwir11'],
  lwir12: ['B12', 'lwir12'],
};

export interface CurrencyType {
  [key: string]: string;
}

export class StacItem {
  // @ts-expect-error
  stac_version: string;
  // @ts-expect-error
  stac_extensions: string[];
  // @ts-expect-error
  id: string;
  // @ts-expect-error
  type: string;
  // @ts-expect-error
  geometry: GeoJSON.GeoJSON;
  // @ts-expect-error
  bbox: GeoJSON.BBox;
  // @ts-expect-error
  properties: IDataCosmosProperties;
  // @ts-expect-error
  links: ILink[];
  // @ts-expect-error
  assets: IAssets;
  collection?: string;
  algebraLayerType?: string;

  /**
   * Create a new StacItem from data in an IStacItem
   * @param data to set the properties in the new StacItem
   */
  public constructor(data: IStacItem) {
    Object.assign(this, data);
  }

  /**
   * Attempts to find the title from the properties, in the following order of preference:
   * platform -> title -> datacosmos:platform_type
   * If none is present, returns the empty string
   * @returns the best title that can be found for this item
   */
  title(): string {
    if (this.properties.platform) {
      return capitaliseFirstLetter(this.properties.platform);
    }
    if (this.properties.title) {
      return this.properties.title;
    }
    if (this.properties['datacosmos:platform_type']) {
      return capitaliseFirstLetter(this.properties['datacosmos:platform_type']);
    }
    if (this.properties.name) {
      return this.properties.name;
    }
    return 'N/A';
  }

  /**
   * Attempts to find the date from the properties, in the following order of preference:
   * datetime -> start_datetime -> end_datetime (first 10 characters of each, assumes ISO8601)
   * If none is present, returns the empty string
   * @returns the best datetime that can be found for the item
   */
  date(): string {
    const datetime =
      (this.properties.datetime ??
        (this.properties.start_datetime as string)) ||
      (this.properties.end_datetime as string) ||
      '';
    if (datetime.includes('T')) {
      return datetime.split('T')[0];
    }
    return datetime.slice(0, 10);
  }

  /**
   * Attempts to find a location from the properties, in the following order of preference:
   * sentinel location information -> landsat location information -> lat/long location information
   * If none is present, returns the empty string
   * @returns the best location information that can be found for the item
   */
  location(): string {
    return (
      (findSentinelLocation(this) ??
        findLandsatLocation(this) ??
        findLatLongLocation(this)) ||
      ''
    );
  }
}

/**
 * @param s the input string to capitalise
 * @returns the input string, with the first letter in upper case and other letters in lower case
 */
function capitaliseFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

/**
 * Attempts to find a Sentinel-2 format location on the StacItem.
 * Returns any parts of that location format it can find, joined by hyphens.
 * @param i a stac item in which to look for Sentinel-2 location information
 * @returns any Sentinel-2 location information found, else undefined
 */
function findSentinelLocation(i: StacItem): string | undefined {
  const locationSegments: string[] = [];
  if (i.properties['sentinel:utm_zone']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    locationSegments.push(i.properties['sentinel:utm_zone']);
  }
  if (i.properties['sentinel:latitude_band']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    locationSegments.push(i.properties['sentinel:latitude_band']);
  }
  if (i.properties['sentinel:grid_square']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    locationSegments.push(i.properties['sentinel:grid_square']);
  }
  if (locationSegments.length > 0) {
    return locationSegments.join('-');
  }
  return undefined;
}

/**
 * Attempts to find a Landsat-8 format location on the StacItem.
 * Returns any parts of that location format it can find, joined by hyphens.
 * @param i a stac item in which to look for Landsat-8 location information
 * @returns any Landsat-8 location information found, else undefined
 */
function findLandsatLocation(i: StacItem): string | undefined {
  const locationSegments: string[] = [];
  if (i.properties['landsat:wrs_path']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    locationSegments.push(i.properties['landsat:wrs_path']);
  }
  if (i.properties['landsat:wrs_row']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    locationSegments.push(i.properties['landsat:wrs_row']);
  }
  if (locationSegments.length > 0) {
    return locationSegments.join('-');
  }
  return undefined;
}

/**
 * Attempts to find a location from the StacItem in lat:long format (to 2dp).
 * Takes the middle points of both, from the bbox.
 * @param i a stac item in which to look for lat/long location information
 * @returns lat/long location information for the item
 */
function findLatLongLocation(i: StacItem): string {
  const halfLat = Number((i.bbox[1] + i.bbox[3]) / 2.0).toFixed(2);
  const halfLon = Number((i.bbox[0] + i.bbox[2]) / 2.0).toFixed(2);
  return `${halfLat},${halfLon}`;
}
