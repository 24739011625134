import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from '_molecules/DatePicker/DatePicker';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import { parseDate } from '@internationalized/date';
import IconButton from '_molecules/IconButton/IconButton';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import { btoaSafe } from 'utils/common/btoaSafe';
import { postTicket } from '_api/tickets/service';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const CarbonSpaceApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Satellite-powered platform for carbon footprint tracking',
  description:
    'Satellite-powered platform for carbon footprint tracking. There are three subscription options: \n\nLite: cheapest, 5 assets, 100 ha @ 30 m or 50000 ha @ 5-10 km, 5 years historical data, 1 country\n\nPlus: more costly, 30 assets, 1000 ha @ 30 m or 500000 @ 5-10 km, historical data since 2000, 5 countries\n\nCustom: POA, unlimited area @ 30m or 5-10km, historical data since 1990, unlimited countries',
  shortDescription: 'Satellite-powered platform for carbon footprint tracking',
  provider: {
    name: 'CarbonSpace',
    description: '',
    id: 11,
    url: 'https://carbonspace.tech/',
    icon_url: DATACOSMOS_IMG + 'carbon_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'carbon_screenshot.png',
  frequencyOfData: 'Less than 1 image per month',
  price: 'Contact us',
  leadTime: '1-2 days',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDateTime',
      example: '',
    },
    {
      field: 'endDateTime',
      example: '',
    },
    {
      field: 'subscriptionType',
      example: '',
    },
  ],
  values: {
    AOI: { value: undefined, isError: false, message: '' },
    startDateTime: { value: undefined, isError: false, message: '' },
    endDateTime: { value: undefined, isError: false, message: '' },
    subscriptionType: { value: undefined, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <CarbonSpace app={app} />,
  tags: [AppTags.environment],
};

const CarbonSpace = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setCarbonAoi,
    applicationAOIs: carbonAoi,
    setInputData,
    getInstalledStatus,
    toggleAppInstall,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();
  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [subscriptionType, setSubscriptionType] = useState<string>();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  useEffect(() => {
    setValue('startDateTime', startDate);
    setValue('endDateTime', endDate);
    setValue('AOI', carbonAoi);
    setValue('subscriptionType', subscriptionType);
  }, [startDate, carbonAoi, endDate, subscriptionType, setValue]);

  const SUBSCRIPTION_TYPES = ['Lite', 'Plus', 'Custom'];

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setCarbonAoi}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setCarbonAoi}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setCarbonAoi([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
            granularity="second"
          />
        </div>

        <div id="endDate">
          <DatePicker
            fill
            label="End date: "
            onChange={(d) => setEndDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
            granularity="second"
          />
        </div>

        <div>
          <Select2
            onSelectionChange={(item) => setSubscriptionType(item.toString())}
            fill
            label={<label htmlFor="analysis">Subscription type: </label>}
            selectedItemClassName="border-2 border-item"
          >
            {SUBSCRIPTION_TYPES.map((item) => (
              <Item key={item}>{item}</Item>
            ))}
          </Select2>
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();

    void postTicket({
      body: {
        title: `DataCosmos Subscription to ${app.name}`,
        description: mailBody,
        team: 'datacosmos',
      },
    });
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(a) => {
        setSelectedInstalledApp(a);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
