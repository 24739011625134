import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import type { IAsset, StacItem } from 'datacosmos/types/stac-types';
import {
  currencySymbols,
  getThumbnailAssetKey,
  isAssetPreviewable,
} from 'datacosmos/utils/stac';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';

export type IStacInfo = ReturnType<typeof useStacInfo>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLayer = (stac: any): stac is SingleBandSTACLayer =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  stac?.cloneWithOptions !== undefined;

const useStacInfo = (stac: SingleBandSTACLayer | StacItem | undefined) => {
  const [selectedBands, setSelectedBands] = useState<[string, IAsset][]>([]);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

  const item = useMemo(() => (isLayer(stac) ? stac.item : stac), [stac]);
  const assetKey = useMemo(
    () => (isLayer(stac) ? stac.assetKey : 'visual'),
    [stac]
  );

  const sunElevationAngle = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const foundSza: number | undefined = itm?.properties?.[
      'view:sun_elevation'
    ] as number | undefined;

    if (foundSza && isFinite(foundSza)) {
      return Number(foundSza).toFixed(2) + '°';
    }
    return 'N/A';
  }, [stac]);

  const cloudCoverage = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const foundClouds: number | undefined = (itm?.properties?.[
      'view:cloud_coverage'
    ] ?? itm?.properties?.['eo:cloud_cover']) as number | undefined;
    if (foundClouds && isFinite(foundClouds)) {
      return Number(foundClouds).toFixed(2) + '%';
    }
    return 'N/A';
  }, [stac]);

  const observationZenithAngle = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;
    const foundOza: number | undefined = itm?.properties?.[
      'view:incidence_angle'
    ] as number | undefined;
    if (foundOza && isFinite(foundOza)) {
      return Number(foundOza).toFixed(2) + '°';
    }
    return 'N/A';
  }, [stac]);

  const sensors = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;
    const foundSensors: string[] | undefined = itm?.properties
      ?.instruments as string[];

    if (foundSensors && Array.isArray(foundSensors)) {
      return foundSensors.join(', ');
    }
    return 'not available';
  }, [stac]);

  const thumbnailAsset = useMemo(() => {
    if (!item) {
      return undefined;
    }
    const thumbKey = getThumbnailAssetKey(item);

    if (!thumbKey) {
      return undefined;
    }

    return item.assets?.[thumbKey];
  }, [item]);

  const stacItemPrice = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const itemPrice: number | undefined = itm?.properties?.[
      'opencosmos:price'
    ] as number | undefined;
    const itemCurrency: string | undefined = itm?.properties?.[
      'opencosmos:price_currency'
    ] as string | undefined;
    const currency = itemCurrency && (currencySymbols[itemCurrency] as string);
    if (itemPrice && isFinite(itemPrice) && currency) {
      return currency + Number(itemPrice);
    }
    return 'N/A';
  }, [stac]);

  const processingLevel = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const levelOfProcessing: string | undefined = itm?.properties?.[
      'processing:level'
    ] as string | undefined;
    if (levelOfProcessing) {
      return levelOfProcessing;
    }
    return 'N/A';
  }, [stac]);

  const sessionIdOfStacItem = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const sessionIdOfItem: string | undefined = itm?.properties?.[
      'opencosmos:session_id'
    ] as string | undefined;
    if (sessionIdOfItem) {
      return sessionIdOfItem;
    }
    return 'N/A';
  }, [stac]);

  const isCartActionDisabled = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const itemPrice: number | undefined = itm?.properties?.[
      'opencosmos:price'
    ] as number | undefined;

    //handles zero case seperately as images with price zero can be added to cart
    if (itemPrice === 0) {
      return false;
    }

    return !itemPrice as boolean;
  }, [stac]);

  const isHighResPermissionGranted = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const isHighResAvailable: boolean = itm?.properties?.[
      'opencosmos:high_resolution_read_permission'
    ]! as boolean;

    return isHighResAvailable;
  }, [stac]);

  const nonPreviewableAssets = useMemo(
    () =>
      Object.entries(item?.assets ?? []).filter(
        ([, val]) => !isAssetPreviewable(val)
      ),
    [item?.assets]
  );
  const previewableAssets = useMemo(
    () =>
      Object.entries(item?.assets ?? []).filter(([, val]) =>
        isAssetPreviewable(val)
      ),
    [item?.assets]
  );

  const handleBandSelect = (itm: [string, IAsset] | undefined) => {
    if (!itm) {
      return;
    }
    setSelectedBands([...selectedBands, itm]);
  };

  const handleBandRemove = (itm: [string, IAsset] | undefined) => {
    if (!itm) {
      return;
    }
    setSelectedBands(selectedBands.filter((b) => b[0] !== itm[0]));
  };

  const isAssetSelected = (itm: [string, IAsset]) => assetKey === itm[0];

  const getSelectedAsset = useCallback(() => {
    const foundAsset = Object.entries(item?.assets ?? {}).find(
      ([key]) => assetKey === key
    );

    const exp = isLayer(stac)
      ? (stac as unknown as BandAlgebraSTACLayer).expression
      : '';
    return (
      foundAsset?.[1]?.title ??
      foundAsset?.[0] ??
      (exp.split('::').length > 1 ? exp.split('::')[1] : exp.split('::')[0])
    );
  }, [assetKey, item?.assets, stac]);

  const getAssetPreviewUrl = useCallback(() => {
    if (!isLayer(stac)) {
      return;
    }

    if (assetKey === 'overview' || assetKey === 'visual') {
      return;
    }

    if (!item) {
      return;
    }

    if (stac instanceof BandAlgebraSTACLayer) {
      const formattedExpression =
        stac.expression.split('::').length > 1
          ? stac.expression.split('::')[1]
          : stac.expression.split('::')[0];
      const bandUrl = tilingApi.generateBandAlgebraPreviewUrl(
        item,
        formattedExpression,
        stac.options.scale,
        stac.options.colormap,
        stac.options.bandAlgebraType,
        stac.options.rgbExpression,
        stac.options.rescaleFalse
      );
      setPreviewUrl(bandUrl);
      return;
    }

    const url = tilingApi.generateAssetPreviewUrl(
      item,
      assetKey,
      stac.options.color_formula
    );
    setPreviewUrl(url);
  }, [assetKey, item, stac]);

  useEffect(() => {
    getAssetPreviewUrl();
  }, [getAssetPreviewUrl]);

  return {
    sunElevationAngle,
    cloudCoverage,
    observationZenithAngle,
    sensors,
    thumbnailAsset,
    previewableAssets,
    nonPreviewableAssets,
    previewUrl,
    isAssetSelected,
    handleBandSelect,
    handleBandRemove,
    getSelectedAsset,
    selectedBands,
    stacItemPrice,
    processingLevel,
    isHighResPermissionGranted,
    isCartActionDisabled,
    sessionIdOfStacItem,
  };
};

export default useStacInfo;
