import React, { useCallback, useState } from 'react';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { Input } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { submitWorkflow } from '_api/pdgs/service';

type Props = {
  app: IApplication;
};

export const OrthorectificationApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.orthorectification.title'),
  description: clientTranslate(
    'datacosmos.applications.orthorectification.description'
  ),
  inputs: [
    {
      field: 'imageId',
      example: '',
    },
  ],
  values: {
    imageId: {
      value: '',
      isError: false,
      message: '',
    },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.orthorectification.shortDescription'
  ),
  renderer: (app: IApplication) => <Orthorectification app={app} />,
  appScreenshotUrl: '',
  tags: [],
};

const Orthorectification = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { translate } = useLocalisation();

  const { clickedStacLayer } = useClickedStacItem({
    outlineClickedItem: false,
    onImageClick: (img) => {
      setValue('imageId', img.item.id);
    },
  });

  const { currentScenario, fetchCurrentProjectItems } = useProjects();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const inputs = () => {
    return (
      <div className="flex flex-col gap-4 mb-4 mt-4">
        <Input
          type="text"
          readOnly
          value={clickedStacLayer?.item.id}
          placeholder={translate(
            'datacosmos.applications.orthorectification.inputs.fullResImg.placeholder'
          )}
          label={{
            position: 'top',
            text: translate(
              'datacosmos.applications.orthorectification.inputs.fullResImg.title'
            ),
            wrapLabelText: true,
          }}
        />
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          setIsSubmitting(true);
          const { data } = await submitWorkflow({
            body: {
              name: 'orthorectify-image',
              inputs: {
                id: clickedStacLayer?.item.id ?? '',
                collection: clickedStacLayer?.item.collection ?? '',
                project: currentScenario?.id ?? '',
              },
            },
          });
          setIsSubmitting(false);

          if (data?.name) {
            await fetchCurrentProjectItems();
          }
        }}
        submitButtonLabel={
          isSubmitting ? (
            <Spinner size={18} />
          ) : (
            translate('datacosmos.applications.global.buttons.submit')
          )
        }
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default Orthorectification;
