import { useEffect, useState } from 'react';
import useGatewaySettings from 'pages/ops/Library/hooks/useGatewaySettings';
import { useMission } from 'services/Missions';
import s from './index.module.scss';
import LibraryNetworkSettings from 'pages/ops/Library/components/NetworkSettings/LibraryNetworkSettings';
import LibraryRadioEncodings from 'pages/ops/Library/components/RadioSettings/LibraryRadioEncodings';
import { ShownCards } from 'pages/ops/Library/components/LibraryEncoding/types';
import { isEqual } from 'lodash';
import type {
  GatewayNetworkConfig,
  GatewayRadioConfig,
} from '_api/gateway/types';
import LibraryHomeCard from './LibraryHomeCard';
import LibrarySaveCard from './LibrarySaveCard';

const LibraryEncoding = () => {
  const { currentMissionId } = useMission();
  const {
    options,
    missionNetworkSettings,
    missionRadioSetings,
    putNetworkSettingsByMission,
    putRadioSettingsByMission,
    deleteRadioSettingsByMission,
  } = useGatewaySettings(currentMissionId ?? 0);

  const [gatewaySettingsChanged, setGatewaySettingsChanged] = useState(false);
  const [gatewaySettingsSaving, setGatewaySettingsSaving] = useState(false);

  const [shownCards, setShownCards] = useState<ShownCards>(
    ShownCards.HOME_CARDS
  );

  const [protocols, setProtocols] = useState<GatewayNetworkConfig[]>(
    missionNetworkSettings
  );
  const [radios, setRadios] =
    useState<GatewayRadioConfig[]>(missionRadioSetings);

  const [protocolToEdit, setProtocolToEdit] = useState<GatewayNetworkConfig>();
  const [radioToEdit, setRadioToEdit] = useState<GatewayRadioConfig>();

  const [shouldDisableSaveButton, setShouldDisableSaveButton] =
    useState<boolean>(false);

  useEffect(() => {
    protocols.length === 0 && setProtocols(missionNetworkSettings);
    radios.length === 0 && setRadios(missionRadioSetings);
  }, [
    missionNetworkSettings,
    missionRadioSetings,
    protocols.length,
    radios.length,
  ]);

  useEffect(() => {
    setGatewaySettingsChanged(false);
  }, []);

  const handleProtocolEdit = (protocol: GatewayNetworkConfig) => {
    const foundProtocol = protocols.find((p) => p.uid === protocol.uid);

    setProtocolToEdit(protocol);
    if (!isEqual(foundProtocol, protocol)) {
      setGatewaySettingsChanged(true);
    } else {
      setGatewaySettingsChanged(false);
    }
  };

  const handleRadioEdit = (radio: GatewayRadioConfig) => {
    setRadioToEdit(radio);
    setGatewaySettingsChanged(true);
  };

  const saveProtocolSettings = (protocol: GatewayNetworkConfig) => {
    if (protocol) {
      setProtocols((prev) => {
        const index = prev.findIndex((p) => p.uid === protocol.uid);

        prev.splice(index, 1, protocol);

        return prev;
      });

      if (!currentMissionId) {
        return;
      }

      void putNetworkSettingsByMission(
        currentMissionId,
        protocol.protocol,
        protocol
      );
    }
  };

  const saveRadioSettings = (radio: GatewayRadioConfig) => {
    if (radio) {
      setRadios((prev) => {
        const index = prev.findIndex((r) => r.uid === radio.uid);

        prev.splice(index, 1, radio);

        return prev;
      });

      if (!currentMissionId) {
        return;
      }

      void putRadioSettingsByMission(currentMissionId, radio.radio, radio);
    }
  };

  const handleSaveGatewaySettings = () => {
    //TODO: replace endpoint with separate network and radio ones to enable editing
    if (!protocolToEdit || !radioToEdit) {
      return;
    }
    saveProtocolSettings(protocolToEdit);
    saveRadioSettings(radioToEdit);
    //setGatewaySettingsSaving(true);
    setGatewaySettingsChanged(false);
    setGatewaySettingsSaving(false);
    // putGatewaySettingsByMission(currentMissionId, gatewaySettings);
  };

  if (!options) {
    return <div className={s.libraryMainContainer}>Loading...</div>;
  }

  return (
    <div className={s.libraryMainContainer}>
      <div className={s.librarySettingsContainer}>
        {shownCards === ShownCards.HOME_CARDS && (
          <>
            <LibraryHomeCard
              networkProtocols={options.protocols}
              radioBands={[]}
              title="Network protocols"
              graphic="constellation"
              data={protocols}
              setShownCards={setShownCards}
              setProtocolToEdit={setProtocolToEdit}
              setProtocols={setProtocols}
              missionId={currentMissionId}
              setRadioToEdit={undefined}
              setRadios={undefined}
              deleteRadioSettingsByMission={deleteRadioSettingsByMission}
            />
            <LibraryHomeCard
              title="Radio protocols"
              graphic="Ground_Segment_Icon"
              data={radios}
              radioBands={options.radios}
              setShownCards={setShownCards}
              setRadioToEdit={setRadioToEdit}
              setRadios={setRadios}
              missionId={currentMissionId}
              deleteRadioSettingsByMission={deleteRadioSettingsByMission}
              networkProtocols={undefined}
              setProtocols={undefined}
              setProtocolToEdit={undefined}
            />
          </>
        )}

        {shownCards === ShownCards.NETWORK_PROTOCOLS && (
          <>
            <LibraryNetworkSettings
              protocolToEdit={protocolToEdit}
              networkConfigOptions={options}
              missionId={currentMissionId}
              handleProtocolEdit={handleProtocolEdit}
              setShownCards={setShownCards}
              setShouldDisableSaveButton={setShouldDisableSaveButton}
            />
          </>
        )}

        {shownCards === ShownCards.RADIO_ENCODING && (
          <>
            <LibraryRadioEncodings
              radioToEdit={radioToEdit}
              networkConfigOptions={options}
              missionId={currentMissionId}
              handleRadioEdit={handleRadioEdit}
              setShownCards={setShownCards}
            />
          </>
        )}
      </div>
      {gatewaySettingsChanged && (
        <div className={s.libraryCardContainer}>
          <LibrarySaveCard
            handleSave={handleSaveGatewaySettings}
            saving={gatewaySettingsSaving}
            shouldDisableSaveButton={shouldDisableSaveButton}
          />
        </div>
      )}
    </div>
  );
};

export default LibraryEncoding;
