import { useState, useRef, useEffect } from 'react';
import {
  ButtonGroup,
  Button,
  EditableText,
  Intent,
  Icon,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';
import {
  useApiCloseOpsTerminalSessions,
  useApiUpdateOpsTerminalSessions,
} from '../../../../../../services/api/ops/terminalSessions';
import SessionConnectionTooltip from './SessionConnectionTooltip';
import SessionSettingPopover from './SessionSettingPopover';
import s from './index.module.scss';
import { useQueries } from '@tanstack/react-query';
import type { TerminalSession } from '_api/terminalSession/types';
import { getUser } from '_api/users/service';

export interface IProps {
  session: TerminalSession;
  handleSessionRename: (name: string) => void;
  handleReloadSessions: () => void;
  handleConnectToSession: (sessionId: number) => void;
  writeAllSessionsPermission: boolean;
  readAllSessionsPermission: boolean;
}

export function TerminalSessionItem({
  handleSessionRename,
  session,
  handleConnectToSession,
  handleReloadSessions,
  writeAllSessionsPermission,
  readAllSessionsPermission,
}: IProps) {
  const editableNameRef = useRef(null);
  const [editableSession, setEditableSession] = useState<boolean>(false);
  const closeSession = useApiCloseOpsTerminalSessions();
  const updateSession = useApiUpdateOpsTerminalSessions();

  const activeUsersQueries = useQueries({
    queries: (session.connectedUsers ?? []).map((user) => {
      return {
        queryKey: [user.userId],
        queryFn: () => {
          if (user.userId.includes('@clients')) {
            return {
              data: {
                email: '',
                email_verified: true,
                id: user.userId,
                name: 'API Key',
                picture: <Icon icon="key" size={10} />,
              },
            };
          }

          return getUser({ params: { subject: user.userId } });
        },
      };
    }),
  });

  const limitedQueries = activeUsersQueries.slice(0, 11);
  const remainingQueries = activeUsersQueries.slice(10);
  const shouldShowRemaining = remainingQueries.length > 0;

  useEffect(() => {
    if (editableSession && editableNameRef.current) {
      //@ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      editableNameRef.current.handleFocus();
    }
  }, [editableSession]);

  const handleDeleteSessionClick = async () => {
    await closeSession(session);
    handleReloadSessions();
  };

  const handleChangeUserAccessibleClick = async () => {
    const sess = Object.assign({}, session, {
      userAccessible: !session.userAccessible,
    });
    await updateSession(sess);
    handleReloadSessions();
  };

  return (
    <li className={s.itemContainer}>
      {session.open ? (
        <>
          <SessionConnectionTooltip sessionConnections={session.connections} />
          <span className={s.sessionId}>#{session.id}</span>
          <EditableText
            ref={editableNameRef}
            className={s.editableText}
            disabled={!editableSession}
            placeholder={editableSession ? 'Click to Edit' : 'No Name'}
            multiline={false}
            value={session.name}
            onChange={handleSessionRename}
            onConfirm={() => {
              void updateSession(session);
              setEditableSession(false);
            }}
          />
          <div style={{ flex: 1 }} />
          <div className="flex items-center max-w-xl overflow-x-hidden mr-3">
            {limitedQueries.map((q) => {
              return (
                <Tooltip
                  content={q.data?.data?.id ?? ''}
                  key={q.data?.data?.id}
                >
                  {typeof q.data?.data?.picture === 'string' ? (
                    <img
                      src={q.data?.data?.picture}
                      className="h-6 w-6 rounded-full"
                    />
                  ) : (
                    <div
                      key={q.data?.data?.id}
                      className="flex h-6 w-6 rounded-full items-center justify-center bg-neutral-400"
                    >
                      {q.data?.data?.picture}
                    </div>
                  )}
                </Tooltip>
              );
            })}
            {shouldShowRemaining && (
              <span className="text-sm">+{remainingQueries.length} more</span>
            )}
          </div>
          {readAllSessionsPermission && (
            <span className={s.sessionId}>
              <Tooltip
                content={
                  session.userAccessible
                    ? 'This session is accessible by the customer'
                    : 'This session is not accessible by the customer'
                }
              >
                <Icon icon={session.userAccessible ? 'eye-open' : 'eye-off'} />
              </Tooltip>
            </span>
          )}
          <ButtonGroup className={s.inlineButtons}>
            <SessionSettingPopover
              userAccessible={session.userAccessible}
              handleRenameSessionClick={() => setEditableSession(true)}
              handleDeleteSessionClick={handleDeleteSessionClick}
              handleChangeUserAccessibleClick={handleChangeUserAccessibleClick}
              writeAllSessionsPermission={writeAllSessionsPermission}
            />
            <Button
              text="Connect"
              icon={IconNames.OFFLINE}
              intent={Intent.PRIMARY}
              onClick={() => handleConnectToSession(session.id)}
            />
          </ButtonGroup>
        </>
      ) : (
        <>
          <SessionConnectionTooltip sessionConnections={session.connections} />
          <span className={s.sessionId}>#{session.id}</span>
          <EditableText
            className={s.editableText}
            disabled
            placeholder={'No Name'}
            value={session.name}
          />
          <div style={{ flex: 1 }} />
          <span>(DELETED)</span>
        </>
      )}
    </li>
  );
}
